import routes from 'routes';
import { Pages } from 'store/app/enums';

export function getCurrentPage(route: string): Pages | null {
  if (route.includes(routes.main)) return Pages.DASHBOARD;
  if (route.includes(routes.organisations.all)) return Pages.ENTITIES;
  if (route.includes(routes.reporting)) return Pages.REPORTING;
  if (route.includes(routes.vies.qualifiedEntities)) return Pages.VIES;
  if (route.includes(routes.users)) return Pages.USERS;
  if (route.includes(routes.support)) return Pages.SUPPORT;
  if (route.includes(routes.whatsNew)) return Pages.WHATS_NEW;
  if (route.includes(routes.account.root)) return Pages.PROFILE;
  if (route.includes(routes.returns.root)) return Pages.RETURN;
  if (route.includes(routes.vat.report)) return Pages.SUBMISSION;
  if (route.includes('/vat/report/connection')) return Pages.REVIEW;

  return null;
}
