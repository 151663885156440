import { call, delay, put } from 'redux-saga/effects';
import {
  fetchAddReturnDataFailure,
  fetchAddReturnDataPostSuccess,
  fetchAddReturnDataPreSuccess,
  fetchAddReturnDataRatesSuccess,
} from '../actions';
import { ApiError } from 'store/api/types';
import { FetchAddReturnDataRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import {
  GetReturnFrequencyResponse,
  GetReturnTypesResponse,
  GetSectionsResponse,
  GetTaxRatesResponse,
  getFrequency,
  getReturnTypes,
  getSections,
  getTaxRates,
} from '../api';
import {
  ReturnFrequencies,
  ReturnType,
  Section,
  TaxRate,
} from '../models/returnData';
import { ReturnFrequency } from 'store/api/enums';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchAddReturnDataSaga(
  action: FetchAddReturnDataRequestAction
) {
  try {
    let err: ApiError | null;
    const { connectionId, loadedReturnData } = action.payload;
    let returnTypes: ReturnType[];
    let returnTypesEdit: ReturnType[];
    let sections: Section[];
    let taxRates: TaxRate[];
    const returnFrequency: ReturnFrequencies = {
      WEEKLY: ReturnFrequency.WEEKLY,
      FORNIGHTLY: ReturnFrequency.FORNIGHTLY,
      MONTHLY: ReturnFrequency.MONTHLY,
      TWO_MONTHS: ReturnFrequency.TWO_MONTHS,
      QUARTERLY: ReturnFrequency.QUARTERLY,
      SIX_MONTHS: ReturnFrequency.SIX_MONTHS,
      YEARLY: ReturnFrequency.YEARLY,
      BI_YEARLY: ReturnFrequency.BI_YEARLY,
    };

    // GETTING INITIAL DATA NEEDED
    if (
      !loadedReturnData ||
      !loadedReturnData.returnTypes ||
      !loadedReturnData.returnTypesEdit
    ) {
      const returnTypesResponse: GetReturnTypesResponse = yield call(
        getReturnTypes,
        connectionId
      );

      err = checkResponse(returnTypesResponse?.responseMessage);
      if (err) throw err;

      returnTypes = returnTypesResponse.data.returnTypes;
      returnTypesEdit = returnTypesResponse.data.returnTypesEdit;
    } else {
      returnTypes = loadedReturnData.returnTypes;
      returnTypesEdit = loadedReturnData.returnTypesEdit;
    }

    yield put(
      fetchAddReturnDataPreSuccess(
        returnTypes,
        returnTypesEdit,
        returnFrequency,
        connectionId
      )
    );
    // CHECKING FOR CONNECTION AND TAX RATES
    if (!loadedReturnData || !loadedReturnData.taxRates) {
      const taxRatesResponse: GetTaxRatesResponse = yield call(
        getTaxRates,
        connectionId
      );

      err = checkResponse(taxRatesResponse?.responseMessage);
      if (err) throw err;
      taxRates = taxRatesResponse?.data.taxRates;
    } else {
      yield delay(1);

      taxRates = loadedReturnData.taxRates;
    }
    yield put(fetchAddReturnDataRatesSuccess(taxRates));

    // IF CONNECTION SUCCESSFUL GET REST OF DATA
    if (!loadedReturnData || !loadedReturnData.sections) {
      const sectionsResponse: GetSectionsResponse = yield call(
        getSections,
        connectionId
      );
      err = checkResponse(sectionsResponse?.responseMessage);
      if (err) throw err;

      sections = sectionsResponse.data.sections;
    } else {
      sections = loadedReturnData.sections;
    }

    yield put(fetchAddReturnDataPostSuccess(sections));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(fetchAddReturnDataFailure());
  }
}
