import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import routes from 'routes';
import {
  fetchMeRequest,
  loginFailure,
  loginMfa,
  loginSuccess,
} from '../actions';
import { login, LoginResponse } from '../api';
import { LoginRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { RememberUser } from '../utils/rememberUser';
import { checkResponse } from 'store/utils';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* loginSaga(action: LoginRequestAction) {
  try {
    let err: ApiError | null;
    const { loginParams, rememberMe, gCaptchaResponse, mfaCode } =
      action.payload;
    const response: LoginResponse = yield call(
      // @ts-ignore
      login,
      loginParams,
      gCaptchaResponse,
      !mfaCode ? null : mfaCode
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const mfaResponse = response?.data;
    if (mfaResponse?.useMFA) {
      yield put(loginMfa(mfaResponse));
    } else {
      yield put(loginSuccess());
      yield put(push(routes.main));

      if (rememberMe) RememberUser.save(loginParams);
      else RememberUser.clear();

      yield put(fetchMeRequest());
    }
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(loginFailure());
  }
}
