import { Spin } from 'antd';
import { CustomersAndSuppliers } from 'components/VatChecker/CustomersAndSuppliers';
import { ViesLogs } from 'components/VatChecker/CustomersAndSuppliers/vies-logs';
import { DownloadCertificate } from 'components/VatChecker/download-Certificate';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  turnDrawerOff,
  turnDrawerOn,
  turnModalOff,
  turnModalOn,
} from 'store/app/actions';
import { DrawerType, WindowType } from 'store/app/enums';
import { Organisation } from 'store/organisation/models';
import { getOrgOrganisations } from 'store/organisation/selectors';
import { DrawerData, ModalData } from 'store/app/types';
import {
  checkContactRequest,
  checkContactsRequest,
  fetchContactRequest,
  fetchContactsRequest,
  refreshContactRequest,
  refreshContactsRequest,
} from 'store/vat-checker/actions';
import { Contact } from 'store/vat-checker/models';
import {
  getViesChecks,
  getViesContacts,
  getViesInfos,
  getViesLoading,
} from 'store/vat-checker/selectors';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import {
  Preference,
  PreferenceActions,
  preferences,
  PreferenceSections,
} from 'utils/preferences';
import { getAuthUser } from 'store/auth/selectors';

export const FILTER_BOX_SELECTED = 'filter-box-selected';

export const CustomersSuppliersPage: React.FC = () => {
  const dispatch = useDispatch();
  const viesInfos = useSelector(getViesInfos);
  const currentUser = useSelector(getAuthUser);
  const viesLogs = useSelector(getViesChecks);
  const contacts = useSelector(getViesContacts);
  const orgs = useSelector(getOrgOrganisations);
  const loading = useSelector(getViesLoading);
  const [selectedOrg, setSelectedOrg] = React.useState<Organisation>();
  const [gettingLogs, setGettingLogs] = React.useState<string>();
  const [showPage, setShowPage] = React.useState(false);
  const params: any = useParams();

  const closeDrawer = () => dispatch(turnDrawerOff());
  const handlePreferences = (
    action: PreferenceActions,
    preference: Preference
  ) => {
    if (currentUser)
      return preferences(
        action,
        PreferenceSections.VIES,
        preference,
        currentUser?.userUuid
      );
    return false;
  };
  React.useEffect(() => {
    setShowPage(true);
  }, []);
  React.useEffect(() => {
    if (params.connectionId && orgs && orgs.length > 0) {
      dispatch(fetchContactsRequest(params.connectionId));
      const currentOrg = orgs?.find(
        (org) => org.connectionId == params.connectionId
      );
      setSelectedOrg(currentOrg);
    }
  }, [orgs]);

  React.useEffect(() => {
    if (gettingLogs && viesLogs) {
      const currentContact = contacts?.find(
        (contact) => contact.name == gettingLogs
      );
      const drawerData: DrawerData = {
        title: gettingLogs,
        type: DrawerType.VIES_LOGS,
        component: (
          <ViesLogs
            viesLogs={viesLogs}
            currentContact={currentContact}
            openDownloadCertificateModal={openDownloadCertificateModal}
            providerName={selectedOrg?.providerName}
            closeDrawer={closeDrawer}
          />
        ),
      };

      dispatch(turnDrawerOn(drawerData));
      setGettingLogs(undefined);
    }
  }, [viesLogs]);

  const openDownloadCertificateModal = (contact: Contact, invoice?: string) => {
    const currentContact = contact
      ? contact
      : contacts?.find((contact) => contact.name == gettingLogs);
    const modalData: ModalData = {
      title: '',
      type: WindowType.DownloadCertificate,
      component: (
        <DownloadCertificate
          contact={currentContact}
          invoice={invoice ? invoice : ''}
          onClose={() => turnModalOff()}
        />
      ),
    };
    dispatch(turnModalOn(modalData));
  };

  const openConfirmWindow = () => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'Check VAT',
      component: (
        <ConfirmBoxContainer
          callback={checkContacts}
          param={params.connectionId}
          showCancelBtn={true}
          okText="Proceed"
          description="This will check all customer and supplier’s VAT on your list. This might take some time."
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const checkContacts = () =>
    dispatch(checkContactsRequest(params.connectionId));

  const checkContact = (contactId) => dispatch(checkContactRequest(contactId));

  const getHistoryLogs = (contact: Contact) => {
    if (contact?.contactUuid) {
      setGettingLogs(contact?.name);
      dispatch(fetchContactRequest(contact?.contactUuid));
    } else alert('Missing connection Id.');
  };

  const onRefreshContacts = (connectionId: string) => {
    dispatch(refreshContactsRequest(connectionId));
  };

  const onRefreshContact = (contactId: string, connectionId: string) => {
    dispatch(refreshContactRequest(contactId, connectionId));
  };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Customers and Suppliers | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for VIES" />
          </Helmet>

          <CustomersAndSuppliers
            loading={loading}
            contacts={contacts}
            organisation={selectedOrg}
            openDownloadCertificateModal={openDownloadCertificateModal}
            openConfirmWindow={openConfirmWindow}
            checkContact={checkContact}
            getHistoryLogs={getHistoryLogs}
            viesInfo={viesInfos.find(
              (vi) => vi.connectionId === params.connectionId
            )}
            onRefreshContacts={onRefreshContacts}
            onRefreshContact={onRefreshContact}
            handlePreferences={handlePreferences}
          />
        </>
      )}
    </React.Fragment>
  );
};
