import { notification } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { turnMessageOff } from 'store/app/actions';
import { getMessage, getShowMessage } from 'store/app/selectors';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/errorIcon.svg';
import { ReactComponent as WarningMessageIcon } from 'assets/images/icons/warningMessageIcon.svg';
import { ReactComponent as InfoMessageIcon } from 'assets/images/icons/infoMessageIcon.svg';
import { ReactComponent as SuccessMessageIcon } from 'assets/images/icons/successMessageIcon.svg';

export enum MessageStates {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
  OPEN,
}

export const NORMAL_TIMEOUT = 8;
export const ERROR_TIMEOUT = 10;

export const MessageBoxContainer: React.FC = () => {
  const dispatch = useDispatch();
  const showMessage = useSelector(getShowMessage);
  const message = useSelector(getMessage);

  const [notificationApi, contextHolder] = notification.useNotification();
  const openMessage = () => {
    let duration = 4;
    if (!message?.duration) {
      if (message?.type === MessageStates.ERROR) duration = ERROR_TIMEOUT;
      else duration = NORMAL_TIMEOUT;
    } else duration = message.duration;

    let props: any = {
      message: message?.title,
      description: message?.description,
      placement: 'bottomLeft',
      duration,
    };
    if (typeof message?.duration === 'number')
      props = { ...props, duration: message.duration };

    if (message?.button) props = { ...props, btn: message.button };

    switch (message?.type) {
      case MessageStates.SUCCESS:
        props = {
          ...props,
          icon: (
            <SuccessMessageIcon style={{ width: '32px', height: '32px' }} />
          ),
          style: {
            border: '1px solid #41AAAF',
            backgroundColor: '#E9F6F7',
            borderRadius: '5px',
            padding: '16px 20px',
            paddingLeft: '12px',
          },
        };
        notificationApi.success(props);
        break;
      case MessageStates.ERROR:
        props = {
          ...props,
          icon: <ErrorIcon style={{ width: '32px', height: '32px' }} />,
          style: {
            border: '1px solid #D13131',
            backgroundColor: '#FCE7E7',
            borderRadius: '5px',
            padding: '16px 20px',
            paddingLeft: '12px',
          },
        };
        notificationApi.error(props);
        break;
      case MessageStates.INFO:
        props = {
          ...props,
          icon: <InfoMessageIcon style={{ width: '32px', height: '32px' }} />,
          style: {
            border: '1px solid #879494',
            backgroundColor: '#F3F7F8',
            borderRadius: '5px',
            padding: '16px 20px',
            paddingLeft: '12px',
          },
        };
        notificationApi.info(props);
        break;
      case MessageStates.WARNING:
        props = {
          ...props,
          icon: (
            <WarningMessageIcon style={{ width: '32px', height: '32px' }} />
          ),
          style: {
            border: '1px solid #FFBC3E',
            backgroundColor: '#FFF5E2',
            borderRadius: '5px',
            padding: '16px 20px',
            paddingLeft: '12px',
          },
        };
        notificationApi.warning(props);
        break;
      case MessageStates.OPEN:
        notificationApi.open(props);
        break;
    }
    dispatch(turnMessageOff());
  };

  React.useEffect(() => {
    showMessage && openMessage();
  }, [showMessage]);

  return <div>{contextHolder}</div>;
};
