import { call, put } from 'redux-saga/effects';
import { changeOwnerFailure, changeOwnerSuccess } from '../actions';
import { changeOwner } from '../api';
import { ChangeOwnerRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { fetchMeRequest } from 'store/auth/actions';
import { fetchUsersRequest } from 'store/user/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* changeOwnerSaga(action: ChangeOwnerRequestAction) {
  try {
    let err: ApiError | null;

    const { accountId } = action.payload;
    const response: ApiResponse = yield call(changeOwner, accountId);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Success !`,
      description: `The owner of the account has been successfully changed!  This account is now a normal user account with limited functionality.`,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(changeOwnerSuccess());
    yield put(fetchMeRequest());
    yield put(fetchUsersRequest());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(changeOwnerFailure());
  }
}
