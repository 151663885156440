import { call, put } from 'redux-saga/effects';
import {
  fetchProfilePictureRequest,
  uploadProfilePictureFailure,
  uploadProfilePictureSuccess,
} from '../actions';
import { uploadProfilePicture } from '../api';
import { UploadProfilePictureRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* uploadProfilePictureSaga(
  action: UploadProfilePictureRequestAction
) {
  try {
    let err: ApiError | null;

    const { base64, userId } = action.payload;
    const response: ApiResponse = yield call(uploadProfilePicture, base64);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(uploadProfilePictureSuccess());
    yield put(fetchProfilePictureRequest(userId));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(uploadProfilePictureFailure());
  }
}
