import { Spin } from 'antd';
import { ConnectEntity } from 'components/ConnectEntity';
import { QualifiedEntities } from 'components/VatChecker';
import { ViesEntityDetails } from 'components/VatChecker/entity-details';
import { push } from 'connected-react-router';
import { EmptyPage } from 'containers/EmptyPage';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes';
import { turnDrawerOn } from 'store/app/actions';
import { DrawerType } from 'store/app/enums';
import { DrawerData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { getCommonCountries } from 'store/common/selectors';
import { disconnectOrganisationsRequest, updateOrganisationData } from 'store/organisation/actions';
import {
  getOrgLoading,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import {
  activateViesRequest,
  deactivateViesRequest,
  fetchViesInfoRequest,
} from 'store/vat-checker/actions';
import { ViesInfo } from 'store/vat-checker/models';
import { getViesInfos, getViesLoading } from 'store/vat-checker/selectors';

export const VIESPage: React.FC = () => {
  const dispatch = useDispatch();
  const orgs = useSelector(getOrgOrganisations);
  const countries = useSelector(getCommonCountries);
  const viesInfos = useSelector(getViesInfos);
  const viesLoading = useSelector(getViesLoading);
  const orgLoading = useSelector(getOrgLoading);
  const currentUser = useSelector(getAuthUser);
  const loading = viesLoading || orgLoading;
  const [showPage, setShowPage] = React.useState(false);
  const [hideAll, setHideAll] = React.useState(true);

  React.useEffect(() => {
    dispatch(fetchViesInfoRequest());
    setShowPage(true);
  }, []);

  React.useEffect(() => {
    if (orgs && !loading) setHideAll(false);
    else setHideAll(true);
  }, [orgs, loading]);

  const activateVatChecker = (connectionId: string, entityName: string) =>
    dispatch(activateViesRequest(connectionId, entityName));

  const deactivateVatChecker = (connectionId: string, entityName: string, entityId: string) => {
    dispatch(deactivateViesRequest(connectionId, entityName));
    dispatch(updateOrganisationData(entityId, false));
  };

  const loadCustomersAndSuppliers = (connectionId: string) => {
    dispatch(
      push(
        routes.vies.customersAndSuppliers.replace(':connectionId', connectionId)
      )
    );
  };

  const disconnectOrganisation = (organisationId: string) => {
    const fromVIES = true;
    dispatch(disconnectOrganisationsRequest(organisationId, fromVIES));
  };

  const onEditEntity = (viesInfo: ViesInfo) => {
    const organisation = orgs?.find(
      (o) => o.connectionId === viesInfo.connectionId
    );
    const drawerData: DrawerData = {
      title: 'Entity details',
      //subtitle: 'Entity Name',
      type: DrawerType.CONNECT_ENTITY,
      component: (
        <ViesEntityDetails
          organisation={organisation}
          countries={countries}
          activeEntity={viesInfo.vatCheckerActive}
          activateVatChecker={activateVatChecker}
          deactivateVatChecker={deactivateVatChecker}
          disconnectOrganisation={disconnectOrganisation}
        />
      ),
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const emptyPageCallback = () => {
    const drawerData: DrawerData = {
      title: 'Add a new entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`VIES | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for VIES" />
          </Helmet>
          <div
            style={{
              padding: '40px',
              paddingTop: '10px',
              maxWidth: 1600,
              minWidth: 1320,
              margin: 'auto',
            }}
          >
            {/* {orgs && orgs.length > 0 ? ( */}
            <QualifiedEntities
              viesInfos={viesInfos}
              loadCustomersAndSuppliers={loadCustomersAndSuppliers}
              activateVatChecker={activateVatChecker}
              loading={loading}
              onEditEntity={onEditEntity}
              hideAll={hideAll}
              currentUser={currentUser}
            />
            {/* ) : ( */}
            <>
              {!hideAll && (!orgs || orgs.length === 0) && (
                <EmptyPage
                  titleText="No Entities found"
                  actionText="Click on the button below to get started"
                  buttonText="Add an entity"
                  actionCallback={emptyPageCallback}
                  //disableButton={!currentUser?.canManageOrgs}
                />
              )}
            </>
            {/* )} */}
          </div>
        </>
      )}
    </React.Fragment>
  );
};
