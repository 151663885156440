import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { VatState } from './types';

const selectSignUp = (app: StoreState): VatState => app.vat;

export const getVatLoading = createSelector(
  selectSignUp,
  (returns: VatState): VatState['loading'] => returns.loading
);

export const getVatPrepLoading = createSelector(
  selectSignUp,
  (returns: VatState): VatState['prepLoading'] => returns.prepLoading
);

export const getVatReturnLogs = createSelector(
  selectSignUp,
  (returns: VatState): VatState['vatReturnLogs'] => returns.vatReturnLogs
);

export const getVatRunData = createSelector(
  selectSignUp,
  (returns: VatState): VatState['vatRunData'] => returns.vatRunData
);

export const getVatReportData = createSelector(
  selectSignUp,
  (returns: VatState): VatState['vatReportData'] => returns.vatReportData
);

export const getVatReconciliation = createSelector(
  selectSignUp,
  (returns: VatState): VatState['reconciliation'] => returns.reconciliation
);

export const getVatActivityLogs = createSelector(
  selectSignUp,
  (returns: VatState): VatState['activityLogs'] => returns.activityLogs
);

export const getVatSuccess = createSelector(
  selectSignUp,
  (returns: VatState): VatState['success'] => returns.success
);

export const getExcelFileData = createSelector(
  selectSignUp,
  (returns: VatState): VatState['excelFileData'] => returns.excelFileData
);
