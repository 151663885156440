export const FETCH_USERS_REQUEST = 'user/fetch-users-request';
export const FETCH_USERS_SUCCESS = 'user/fetch-users-success';
export const FETCH_USERS_FAILURE = 'user/fetch-users-failure';

export const SET_USER_PERMISSIONS_REQUEST = 'user/set-user-permissions-request';
export const SET_USER_PERMISSIONS_SUCCESS = 'user/set-user-permissions-success';
export const SET_USER_PERMISSIONS_FAILURE = 'user/set-user-permissions-failure';

export const ADD_MEMBER_REQUEST = 'user/add-member-request';
export const ADD_MEMBER_SUCCESS = 'user/add-member-success';
export const ADD_MEMBER_FAILURE = 'user/add-member-failure';

export const DELETE_USER_REQUEST = 'user/delete-user-request';
export const DELETE_USER_SUCCESS = 'user/delete-user-success';
export const DELETE_USER_FAILURE = 'user/delete-user-failure';

export const FETCH_USER_HISTORY_REQUEST = 'user/fetch-user-history-request';
export const FETCH_USER_HISTORY_SUCCESS = 'user/fetch-user-history-success';
export const FETCH_USER_HISTORY_FAILURE = 'user/fetch-user-history-failure';

export const FETCH_USER_RIGHTS_REQUEST = 'user/fetch-user-rights-request';
export const FETCH_USER_RIGHTS_SUCCESS = 'user/fetch-user-rights-success';
export const FETCH_USER_RIGHTS_FAILURE = 'user/fetch-user-rights-failure';

export const SET_USER_RIGHTS_REQUEST = 'user/set-user-rights-request';
export const SET_USER_RIGHTS_SUCCESS = 'user/set-user-rights-success';
export const SET_USER_RIGHTS_FAILURE = 'user/set-user-rights-failure';

export const SET_ALL_USER_RIGHTS_REQUEST = 'user/set-all-user-rights-request';
export const SET_ALL_USER_RIGHTS_SUCCESS = 'user/set-all-user-rights-success';
export const SET_ALL_USER_RIGHTS_FAILURE = 'user/set-all-user-rights-failure';

export const RESET_USER_DATA = 'user/reset-user-data';
