export const SET_LOCALE = 'app/set-locale';
export const SET_CURRENT_PAGE = 'app/set-current-page';
export const TURN_MESSAGE_ON = 'app/turn-message-on';
export const TURN_MESSAGE_OFF = 'app/turn-message-off';
export const TURN_MODAL_ON = 'app/turn-modal-on';
export const TURN_MODAL_OFF = 'app/turn-modal-off';
export const TURN_DRAWER_ON = 'app/turn-drawer-on';
export const TURN_DRAWER_OFF = 'app/turn-drawer-off';
export const SET_REFRESH_DONE = 'app/set-refresh-done';

export const SET_MENU_SIZE = 'app/set-menu-size';
export const UPDATE_MAPPING_DATA = 'app/update-mapping-data';
export const UPDATE_USER_PICTURES = 'app/update-user-pictures';
export const PICTURE_DELAY_TIME = 750;
