import { call, delay, put, select } from 'redux-saga/effects';
import { fetchActivityLogsFailure, fetchActivitySuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { FetchActivityLogsRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { GetActivityLogsResponse, getActivityLogs } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData, ProfilePicture } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { PICTURE_DELAY_TIME } from 'store/app/constants';
import { ActivityLog } from '../models';
import { getProfilePictures } from 'store/app/selectors';
import { fetchProfilePictureRequest } from 'store/account/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchActivityLogsSaga(action: FetchActivityLogsRequestAction) {
  try {
    let err: ApiError | null;
    const { dataLogId } = action.payload;

    const response: GetActivityLogsResponse = yield call(
      getActivityLogs,
      dataLogId
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const activityLogs: ActivityLog[] = response.data.activities;
    yield put(fetchActivitySuccess(activityLogs));

    let pp: ProfilePicture[] = yield select(getProfilePictures);
    let newActivityLogs: ActivityLog[] = [...activityLogs];
    for (let i = 0; i < activityLogs.length; i++) {
      const user = activityLogs[i].userDto;
      if (user && user.hasProfilePicture && !user.profilePicture) {
        const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
        if (ppIdx > -1) {
          const profilePicture = pp[ppIdx].profilePicture;
          newActivityLogs[i].userDto = {
            ...newActivityLogs[i].userDto,
            profilePicture,
          };
        } else {
          yield put(fetchProfilePictureRequest(user.userUuid));
          yield delay(PICTURE_DELAY_TIME);
          pp = yield select(getProfilePictures);
          const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
          if (ppIdx > -1) {
            const profilePicture = pp[ppIdx].profilePicture;
            newActivityLogs[i].userDto = {
              ...newActivityLogs[i].userDto,
              profilePicture,
            };
          }
        }
      }
    }
    yield put(fetchActivitySuccess(newActivityLogs));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(fetchActivityLogsFailure());
  }
}
