import { Space, Spin, Switch, Typography } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Provider, TablePermission } from 'store/api/enums';
import { UserRights } from 'store/user/models';
import { SearchOutlined } from '@ant-design/icons';
import { RoundSearchSelect } from 'components/Custom/selects';
import { ScopeButton } from 'components/Custom/buttons';
import { Entity } from 'store/organisation/models';
import { GraySpan } from 'components/Custom/spans';
import { RoundInput } from 'components/Custom/inputs';

const StyledSwitch = styled(Switch)`
  .ant-switch-inner {
    background: #ffffff !important;
  }

  .ant-switch-handle::before {
    background: ${(props) => (props.checked ? '#41aaaf' : '#CCD4D4')}!important;
  }

  .switch-checked {
    .ant-switch-handle::before {
      background: #41aaaf !important;
    }
  }
`;

interface RightsProps {
  loading: boolean;
  rights?: UserRights[];
  saveUserRights: (
    userRight: UserRights,
    TablePermission: TablePermission
  ) => void;
  saveAllUserRights: (
    userRight: UserRights[],
    TablePermission: TablePermission
  ) => void;
  onClose: () => void;
  entities: Entity[] | undefined;
}
const { Text } = Typography;
export const Rights: React.FC<RightsProps> = ({
  loading,
  rights,
  saveUserRights,
  saveAllUserRights,
  entities,
  onClose,
}) => {
  const { t } = useTranslation();
  const [access, setAccess] = React.useState<any>();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const [rightsList, setRightsList] = React.useState<UserRights[] | undefined>(
    []
  );

  React.useEffect(() => {
    const defaultRights = {};
    rights?.map((r) => {
      return (defaultRights[r.uuid] =
        TablePermission[r.tablePermission] !== TablePermission.NONE);
    });
    if (!access && (!rightsList || rightsList.length === 0)) {
      setAccess(defaultRights);
      setRightsList(rights);

      if (Object.keys(defaultRights).findIndex((k) => !defaultRights[k]) === -1)
        setSelectAll(true);
    }
  }, [rights]);

  const renderName = (record: UserRights) => {
    const friendlyName = record?.friendlyName;
    const providerName = record?.providerName;

    return (
      <Space size={'small'} style={{ width: 215 }}>
        <Link to={'#'}>
          <img
            src={
              providerName
                ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                    .default
                : ''
            }
            width={30}
          />
        </Link>
        <Text
          style={{
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          {friendlyName ? friendlyName : providerName}
        </Text>
      </Space>
    );
  };

  const renderSwitch = (record: UserRights) => (
    <Space style={{ columnGap: 12, minWidth: 76 }}>
      <GraySpan style={{ minWidth: 20, display: 'block' }}>
        {access && access[record.uuid] ? t('common.yes') : t('common.no')}
      </GraySpan>
      <StyledSwitch
        className={access && access[record.uuid] ? 'switch-checked' : ''}
        checked={access && access[record.uuid]}
        onChange={(e) => {
          saveUserRights(
            record,
            e ? TablePermission.ALL : TablePermission.NONE
          );

          const acc = { ...access };

          acc[record.uuid] = !acc[record.uuid];

          setAccess(acc);
        }}
      ></StyledSwitch>
    </Space>
  );

  const handleSearch = (_e) => {
    if (searchValue != '') {
      setRightsList(
        rights?.filter((right) =>
          right?.friendlyName
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      );
    } else {
      setRightsList(rights);
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == '') {
      setRightsList(rights);
    }
  };

  const onSelectAll = () => {
    if (rightsList) {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
      const acc = { ...access };
      saveAllUserRights(
        rightsList,
        newSelectAll ? TablePermission.ALL : TablePermission.NONE
      );
      rightsList?.forEach((rl) => {
        acc[rl.uuid] = newSelectAll;
      });
      setAccess(acc);
    }
  };

  return (
    <Spin spinning={loading} size="large">
      <Space direction="vertical">
        <RoundInput
          width="317px"
          height="45px" //@ts-ignore
          bg_color="white"
          size="large"
          style={{ marginBottom: '20px' }}
          value={searchValue}
          placeholder={`${t('common.search')} ${t('organisation.entities')}`}
          onChange={handleChange}
          suffix={
            <SearchOutlined
              width={33}
              height={33}
              style={{
                backgroundColor: '#41AAAF',
                color: 'white',
                padding: '8px',
                borderRadius: '50px',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={handleSearch}
            />
          }
        />
        <div>
          <Typography.Text
            style={{
              fontSize: '18px',
              fontWeight: '400',
              //lineHeight: '24px',
              textAlign: 'left',
              color: '#042A2A',
              //marginBottom: '10px',
            }}
          >
            {t('organisation.entities')}
          </Typography.Text>
        </div>

        <Space
          //key={right.uuid}
          style={{
            columnGap: 30,
            rowGap: 30,
            paddingBottom: 14,
            borderBottom: '1px solid #DBEAE3',
          }}
        >
          <div style={selectAll ? { width: 172 } : { width: 182 }} />

          <div>
            <Space style={{ columnGap: 12, minWidth: 76 }}>
              <GraySpan style={{ minWidth: 20, display: 'block' }}>
                {selectAll ? 'Remove All' : 'Select All'}
              </GraySpan>
              <StyledSwitch
                className={selectAll ? 'switch-checked' : ''}
                checked={selectAll}
                onChange={onSelectAll}
              ></StyledSwitch>
            </Space>
          </div>
        </Space>
        <div className="scroller-rights">
          {rightsList?.map((right) => {
            return (
              <Space
                key={right.uuid}
                style={{
                  columnGap: 30,
                  rowGap: 30,
                  padding: '14px 0px',
                  borderBottom: '1px solid #DBEAE3',
                }}
              >
                {renderName(right)}
                {renderSwitch(right)}
              </Space>
            );
          })}
        </div>
        <div style={{ marginTop: '20px' }}>
          <ScopeButton
            type="default" //@ts-ignore
            width="100%"
            height="40px"
            bg_color="#E9F6F7"
            onClick={() => onClose()}
          >
            {t('common.close')}
          </ScopeButton>
        </div>
      </Space>
    </Spin>
  );
};
