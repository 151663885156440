import React from 'react';
import { Avatar } from 'antd';
import { StyledTooltip } from 'components/Custom/Tooltip';

export enum AvatarSizes {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

export const renderAvatar = (
  firstName: string,
  lastName: string,
  size: AvatarSizes,
  userUuid?: string,
  userImage?: string
) => {
  let avatarFontSize = 0;
  let avatarSize = 0;

  switch (size) {
    case AvatarSizes.SMALL:
      avatarFontSize = 10;
      avatarSize = 24;
      break;
    case AvatarSizes.DEFAULT:
      avatarFontSize = 12;
      avatarSize = 32;
      break;
    case AvatarSizes.LARGE:
      avatarFontSize = 14;
      avatarSize = 40;
      break;
  }

  if (firstName) {
    const firstChar = firstName.charAt(0);
    let secChar = '';
    if (lastName) {
      secChar = lastName.charAt(0);
    }
    return (
      <StyledTooltip title={firstName + ' ' + lastName} fontSize="14px">
        <Avatar
          style={{
            backgroundColor: '#879494',
            verticalAlign: 'middle',
            fontSize: avatarFontSize,
          }}
          size={avatarSize}
          src={userImage && `data:image/png;base64,${userImage}`}
        >
          {!userImage && firstChar + secChar}
        </Avatar>
      </StyledTooltip>
    );
  }
};
