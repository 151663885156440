import { call, put } from 'redux-saga/effects';
import { fetchTimezonesFailure, fetchTimezonesSuccess } from '../actions';
import { TimezonesResponse, getTimezones } from '../api';
import { Timezone } from '../models';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchTimezonesSaga() {
  try {
    let err: ApiError | null;
    const response: TimezonesResponse = yield call(getTimezones);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const timezones: Timezone[] = response.data.timezones;
    yield put(fetchTimezonesSuccess(timezones));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(fetchTimezonesFailure());
  }
}
