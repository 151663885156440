import { Space, Spin, Typography, Popover } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ScopeTable } from 'components/Custom/table';
import {
  IconContextButton,
  ToggleButton,
  ScopeButton,
  ContextButton,
  IconButton,
} from 'components/Custom/buttons';
import { useTranslation } from 'react-i18next';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  PlusOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as ConnectIcon } from 'assets/images/icons/connectIcon.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/userIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import { ReactComponent as RefreshIcon } from 'assets/images/icons/refreshIcon.svg';
import { getProviderLink } from 'utils/get-provider-link';
import { ColoredTag, StrongTag } from 'components/Custom/spans';
import { Country } from 'store/common/models';
import { AvatarSizes } from 'utils/render-avatar';
import { Entity } from 'store/organisation/models';
import { AvatarGroupRenderer } from 'utils/avatar-group-renderer';
import { EmptyPage } from 'containers/EmptyPage';
import { User } from 'store/auth/models';
import { canEditTaxReturn } from 'utils/canEditTaxReturn';
import { AccountType } from 'store/api/enums';
import { StyledTooltip } from 'components/Custom/Tooltip';

interface EntityComponentProps {
  loading: boolean;
  mappedOrganisations?: Entity[];
  countries: Country[];
  addReturnMapping: (connectionId: string) => void;
  editReturnMapping: (connectionId: string, mappingId: string) => void;
  addEntity: () => void;
  //connectEntity: () => void;
  editUsers: (entity: Entity) => void;
  currentUser?: User;
}

const { Text } = Typography;

export const EntityComponent: React.FC<EntityComponentProps> = ({
  loading,
  mappedOrganisations,
  countries,
  addReturnMapping,
  editReturnMapping,
  addEntity,
  //connectEntity,
  editUsers,
  currentUser,
}) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState(mappedOrganisations);
  const [taxReturnNames, setTaxReturnNames] = useState([]);

  useEffect(() => {
    const sortedByName = mappedOrganisations?.sort((a, b) =>
      a.organisation.localeCompare(b.organisation)
    );

    setDataSource(sortedByName); //@ts-ignore
    setTaxReturnNames(getTaxReturns());
  }, [mappedOrganisations]);

  const onAddReturn = (connectionId: string) => {
    addReturnMapping(connectionId);
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const renderOrgLink = (content: string, record: Entity) => {
    const to = `/entities/organisation/${record.organisationId}`;
    return (
      <Space size={'small'} style={{ minWidth: '140px' }}>
        <a
          href={getProviderLink(
            record.providerName.toLowerCase(),
            record.shortCode
          )}
          target="_blank"
          rel="noreferrer"
          style={{ height: '32px', display: 'block' }}
        >
          <img
            src={
              record?.providerName
                ? require(`shared/assets/images/${record.providerName.toLowerCase()}_small.png`)
                    .default
                : ''
            }
            width={25}
            style={{ paddingTop: '4px' }}
          />
        </a>
        <StyledTooltip
          key={record.organisationId} // @ts-ignore
          placement="top"
          title={t('main.click-to-view-details')}
        >
          <Link to={to}>
            <Text style={{ fontSize: '13px' }}>{content}</Text>
          </Link>
        </StyledTooltip>
      </Space>
    );
  };

  const renderRemainingReturns = (record: Entity, connectionId, returnArr) => {
    return (
      <Space direction="vertical" style={{ rowGap: '0' }}>
        {returnArr.map((map) => {
          return (
            <>
              {canEditTaxReturn(
                record.connectedProperly,
                currentUser,
                map.dataLog?.status
              ) ? (
                <StyledTooltip
                  key={map.mappingId} // @ts-ignore
                  placement="top"
                  title={t('main.click-to-edit')}
                >
                  <StrongTag
                    key={map.mappingId}
                    onClick={() =>
                      editReturnMapping(connectionId, map.mappingId)
                    }
                  >
                    {map.mappingName}
                  </StrongTag>
                </StyledTooltip>
              ) : (
                <StyledTooltip
                  key={map.mappingId} // @ts-ignore
                  placement="top"
                  title="Cannot edit return with submission in progress."
                >
                  <StrongTag key={map.mappingId}>{map.mappingName}</StrongTag>
                </StyledTooltip>
              )}
            </>
          );
        })}
      </Space>
    );
  };

  const renderMappingLink = (content: string, record: Entity) => {
    return (
      <Space
        size={'small'}
        style={{
          //minWidth: '660px',
          columnGap: 3,
        }}
        wrap
      >
        {record.returns.map((mappedReturn, index, returnArr) => {
          if (index <= 1) {
            return (
              <>
                {canEditTaxReturn(
                  record.connectedProperly,
                  currentUser,
                  mappedReturn.dataLog?.status
                ) ? (
                  <StyledTooltip
                    key={mappedReturn.mappingId} // @ts-ignore
                    placement="top"
                    title={t('main.click-to-edit')}
                  >
                    <StrongTag
                      key={mappedReturn.mappingId}
                      onClick={() =>
                        editReturnMapping(
                          record.connectionId,
                          mappedReturn.mappingId
                        )
                      }
                    >
                      {mappedReturn.mappingName}
                    </StrongTag>
                  </StyledTooltip>
                ) : (
                  <StyledTooltip
                    key={mappedReturn.mappingId} // @ts-ignore
                    placement="top"
                    title={
                      mappedReturn.connectedProperly
                        ? 'Cannot edit return with submission in progress.'
                        : 'Cannot edit return. Disconnected entity.'
                    }
                  >
                    <StrongTag>{mappedReturn.mappingName}</StrongTag>
                  </StyledTooltip>
                )}
              </>
            );
          } else if (index == 2) {
            return (
              <Popover
                key={returnArr.length + 2}
                placement="topRight"
                arrow={false}
                trigger="click"
                content={renderRemainingReturns(
                  record,
                  record.connectionId,
                  record.returns.slice(index)
                )}
                title={''}
              >
                <StrongTag key={returnArr.length}>
                  {returnArr.length - index}
                </StrongTag>
              </Popover>
            );
          }
        })}
        {record.connectedProperly && (
          <ToggleButton
            type="primary"
            shape="circle"
            icon={<PlusOutlined />} //@ts-ignore
            paddinglft="94px"
            onClick={() => onAddReturn(record.connectionId)}
          >
            <span className="show-on-hover">
              {t('organisation.add-return')}
            </span>
          </ToggleButton>
        )}
      </Space>
    );
  };

  const renderTaxReturn = (content, record: Entity) => {
    return record.returns.length > 0 ? (
      renderMappingLink(content, record)
    ) : (
      <>
        {record.connectedProperly &&
          currentUser &&
          AccountType[currentUser?.accountType] !== AccountType.CLIENT && (
            <ScopeButton
              type={'primary'}
              onClick={() => onAddReturn(record.connectionId)} // @ts-ignore
              width={'99px'}
              height={'25px'}
              fontSize={'12px'}
              lineheight={'12px'}
              style={{ padding: '4px 5px' }}
              icon={<PlusOutlined width={'16px'} height={'16px'} />}
            >
              <span>{t('organisation.add-return')}</span>
            </ScopeButton>
          )}
      </>
    );
  };

  const renderContextMenu = (record) => {
    const to = `/entities/organisation/${record.organisationId}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={<ConnectIcon style={{ width: '16px', height: '17px' }} />}
            onClick={() => onAddReturn(record.connectionId)}
          >
            {t('organisation.add-tax-return')}
          </IconContextButton>
          <IconContextButton
            type="text"
            icon={<UserIcon style={{ width: '18px', height: '20px' }} />}
            onClick={() => editUsers(record)}
          >
            {t('users.edit-user')}
          </IconContextButton>
          <IconContextButton
            type="text"
            icon={<EyeIcon style={{ width: '18px', height: '22px' }} />}
          >
            <Link style={{ textDecoration: 'none' }} to={to}>
              {t('organisation.view-entity')}
            </Link>
          </IconContextButton>
        </Space>
      </div>
    );
  };

  // const getTaxReturns = () => {
  //   const taxReturns: string[] = [];
  //   dataSource?.forEach((item) => {
  //     item.returns.forEach((tax) => taxReturns.push(tax.mappingName));
  //   });
  //   return taxReturns;
  // };
  const checkEntityMessage = () => {
    let message = 2;
    if (!dataSource || dataSource.length === 0) message = 1;
    if (
      !!dataSource &&
      dataSource.findIndex((ds) => ds.returns.length > 0) > -1
    )
      message = 0;

    if (message === 1) {
      return (
        <EmptyPage
          titleText={'No Entities found'}
          actionText={'Click on the button below to get started'}
          buttonText={'Add an entity'}
          actionCallback={addEntity}
          disableButton={!currentUser?.canManageOrgs}
        />
      );
    } else if (message === 2) {
      return (
        <EmptyPage
          titleText="Add Tax return"
          actionText='Click on "Add return" button below to get started'
          messageOnly
        />
      );
    } else return;
  };
  const getTaxReturns = () => {
    const taxReturns: string[] = [];
    dataSource?.forEach((item) => {
      item.returns.forEach((tax) => taxReturns.push(tax.mappingName));
    });
    return taxReturns;
  };

  const columns = [
    {
      title: t('organisation.name'),
      dataIndex: 'organisation',
      //key: 'organisation',
      width: '240px',
      render: renderOrgLink,
      sorter: (a, b) => a.organisation.localeCompare(b.organisation),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.country'),
      dataIndex: 'country',
      //key: 'country',
      render: (content) => {
        const countryName = countries.find((c) => c.iso === content)?.name;
        return countryName ? countryName : content;
      },
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.mappingName'),
      dataIndex: 'returns',
      //key: 'returns',
      render: renderTaxReturn,
      /*sorter: (a, b) => {
        a.returns.sort((a, b) => a.mappingName.localeCompare(b.mappingName));
        b.returns.sort((a, b) => a.mappingName.localeCompare(b.mappingName));
      },
      sortIcon: renderSortIcon,*/
    },
    {
      title: t('users.users'),
      dataIndex: 'users',
      //key: 'users',
      //sorter: (a, b) => a.users.localeCompare(b.users),
      //sortIcon: renderSortIcon,
      render: (_, record: Entity) => (
        <AvatarGroupRenderer entity={record} size={AvatarSizes.DEFAULT} />
      ),
      responsive: ['lg'],
    },
    {
      title: t('main.VAT'),
      dataIndex: 'vatNumber',
      //key: 'vatNumber',
      sorter: (a, b) => a.vatNumber?.localeCompare(b.vatNumber),
      sortIcon: renderSortIcon,
      render: (content) => (
        <Typography.Text
          style={{
            maxWidth: 200,
            //fontSize: 11,
            //fontWeight: 400,
            //color: '#042a2a',
          }} //@ts-ignore
          ellipsis={{ rows: 1, tooltip: true }}
        >
          {content}
        </Typography.Text>
      ),
    },
    {
      title: 'Connection status', // t('organisation.last-connected'),
      dataIndex: 'client',
      //key: 'client',
      sortIcon: renderSortIcon,
      render: (_, record: Entity) => {
        if (record.connectedProperly)
          return (
            <ColoredTag
              color="#058181" //@ts-ignore
              bgcolor="#E9F6F7"
              bordered={true}
              bordercolor="#DBEAE3"
              icon={<CheckOutlined />}
            >
              {t('main.connected')}
            </ColoredTag>
          );
        else
          return (
            <IconButton
              type="default"
              style={{
                height: '30px',
                borderRadius: 10,
                display: 'flex',
                alignItems: 'center',
                padding: '14px 10px',
              }}
              icon={<RefreshIcon width={'18px'} height={'18px'} />}
              onClick={addEntity}
            >
              {t('main.reconnect')}
            </IconButton>
          );
      },
      responsive: ['lg'],
    },
    {
      render: (_, record: Entity) => {
        return (
          <Popover
            placement="topLeft"
            arrow={false}
            trigger="click"
            content={renderContextMenu(record)}
            title={''}
            overlayStyle={{ zIndex: 500 }}
          >
            <ContextButton type="text">
              <EllipseIcon
                style={{
                  height: '10px',
                }}
              />
            </ContextButton>
          </Popover>
        );
      },
    },
  ];

  if (
    currentUser &&
    AccountType[currentUser.accountType] === AccountType.CLIENT // Removing users column
  )
    columns.splice(3, 1);

  return (
    <React.Fragment>
      <div
        style={{
          padding: '40px',
          paddingTop: 0,
          maxWidth: 1600,
          minWidth: 1320,
          margin: 'auto',
        }}
      >
        <Spin spinning={loading} size="large">
          {checkEntityMessage()}
          {!!dataSource &&
            dataSource.length > 0 && ( //@ts-ignore
              <ScopeTable
                filters={[
                  'organisation',
                  'country',
                  'returns.mappingName',
                  'users',
                ]} //@ts-ignore
                tableDataSource={dataSource} //@ts-ignore
                originalDataSource={mappedOrganisations?.sort((a, b) =>
                  a.organisation.localeCompare(b.organisation)
                )}
                columns={columns}
                pagination={true}
                rowsPerPage={15}
                enableColumnsCustomization={false}
                haveBorder
              />
            )}
        </Spin>
      </div>
    </React.Fragment>
  );
};
