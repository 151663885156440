import { Modal } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { turnModalOff } from 'store/app/actions';
import { getModalData, getModalOpen } from 'store/app/selectors';
import styled from 'styled-components';

const ModalWindowContainerWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
    min-width: fit-content;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-header {
    margin-top: 25px;
  }
`;

export const ModalWindowContainer: React.FC = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const modalOpen = useSelector(getModalOpen);

  return (
    <ModalWindowContainerWrapper
      title={modalData?.title}
      centered
      open={modalOpen}
      onCancel={() => dispatch(turnModalOff())}
      footer={false}
    >
      {modalData?.component}
    </ModalWindowContainerWrapper>
  );
};
