import { call, put } from 'redux-saga/effects';
import {
  awaitingApprovalFailure,
  awaitingApprovalSuccess,
  changeStatusAfterUpdate,
  fetchActivityLogsRequest,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { AwaitingApprovalRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { awaitingApproval } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { DataLogStatus, SubmissionUpdateField } from 'store/app/enums';
import { updateSubmissionLog } from 'store/organisation/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* awaitingApprovalSaga(action: AwaitingApprovalRequestAction) {
  try {
    let err: ApiError | null;
    const { dataLogId, reportUrl, mappingId } = action.payload;

    const response: ApiResponse = yield call(
      awaitingApproval,
      dataLogId,
      reportUrl
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success !',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    yield put(fetchActivityLogsRequest(dataLogId));
    yield put(changeStatusAfterUpdate(DataLogStatus.AWAITING_APPROVAL));
    yield put(
      updateSubmissionLog(
        mappingId,
        SubmissionUpdateField.STATUS,
        'AWAITING_APPROVAL',
        dataLogId
      )
    );
    yield put(awaitingApprovalSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(awaitingApprovalFailure());
  }
}
