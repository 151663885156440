import { call, put } from 'redux-saga/effects';
import { ApiError, ApiResponse } from 'store/api/types';
import { RefreshContactRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { refreshContact } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import {
  fetchContactsRequest,
  refreshContactFailure,
  refreshContactSuccess,
} from '../actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* refreshContactSaga(action: RefreshContactRequestAction) {
  try {
    let err: ApiError | null;
    const { contactId, connectionId } = action.payload;

    const response: ApiResponse = yield call(
      refreshContact,
      contactId,
      connectionId
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(refreshContactSuccess());
    yield put(fetchContactsRequest(connectionId));
    //yield put(fetchContactRequest(contactId));
    const message: MessageData = {
      title: 'Success',
      description: 'Contact refreshed.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(refreshContactFailure());
  }
}
