import { call, put } from 'redux-saga/effects';
import { setUserRightsFailure, setUserRightsSuccess } from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { setAllUserRights, setUserRights } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { SetAllUserRightsRequestAction } from '../types';
import { updateEntityMembers } from 'store/organisation/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* setAllUserRightsSaga(action: SetAllUserRightsRequestAction) {
  try {
    let err: ApiError | null;
    const { userRights, tablePermission, user } = action.payload;
    const response: ApiResponse = yield call(
      setAllUserRights,
      userRights[0].accountUuid,
      tablePermission
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    if (user) {
      for (let i = 0; i < userRights.length; i++) {
        yield put(
          updateEntityMembers(
            userRights[i].organisationId,
            user,
            tablePermission
          )
        );
      }
    }
    for (let i = 0; i < userRights.length; i++) {
      yield put(setUserRightsSuccess(userRights[i].uuid, tablePermission));
    }
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(setUserRightsFailure());
  }
}
