import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_NOTE_REQUEST,
  AWAITING_APPROVAL_REQUEST,
  CANCEL_RETURN_REQUEST,
  CLIENT_APPROVE_REQUEST,
  FETCH_ACTIVITY_LOGS_REQUEST,
  FETCH_EXCEL_FILE_REQUEST,
  FETCH_RUN_DATA_REQUEST,
  FETCH_VAT_LOGS_REQUEST,
  FETCH_VIEW_RUN_DATA_REQUEST,
  GENERATE_REPORT_REQUEST,
  INTERNALLY_APPROVE_REQUEST,
  LOAD_RECON_REQUEST,
  LOAD_REPORT_REQUEST,
  LOCK_RETURN_REQUEST,
  NOT_APPROVE_REQUEST,
  SAVE_RETURN_REQUEST,
  SEND_CLIENT_APPROVAL_REQUEST,
  SUBMIT_RETURN_REQUEST,
  UPDATE_ACTIVITY_ROLE_REQUEST,
} from '../constants';
import { fetchVatLogsSaga } from './fetchVatLogsSaga';
import { fetchRunDataSaga } from './fetchRunDataSaga';
import { generateReportSaga } from './generateReportSaga';
import { loadReconSaga } from './loadReconSaga';
import { saveReturnSaga } from './saveReturnSaga';
import { fetchViewRunDataSaga } from './fetchViewRunDataSaga';
import { loadReportSaga } from './loadReportSaga';
import { cancelReturnSaga } from './cancelReturnSaga';
import { updateActivityRoleSaga } from './updateActivityRoleSaga';
import { addNoteSaga } from './addNoteSaga';
import { fetchActivityLogsSaga } from './fetchActivityLogsSaga';
import { awaitingApprovalSaga } from './awaitingApprovalSaga';
import { internallyApproveSaga } from './internallyApproveSaga';
import { sendClientApprovalSaga } from './sendClientApprovalSaga';
import { clientApproveSaga } from './clientApproveSaga';
import { notApproveSaga } from './notApprovedSaga';
import { submitReturnSaga } from './submitReturnSaga';
import { lockReturnSaga } from './lockReturnSaga';
import { fetchExcelFileSaga } from './fetchExcelFileSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* vatSaga() {
  yield takeEvery(FETCH_VAT_LOGS_REQUEST, fetchVatLogsSaga);
  yield takeLatest(FETCH_RUN_DATA_REQUEST, fetchRunDataSaga);
  yield takeLatest(FETCH_VIEW_RUN_DATA_REQUEST, fetchViewRunDataSaga);
  yield takeLatest(GENERATE_REPORT_REQUEST, generateReportSaga);
  yield takeLatest(LOAD_REPORT_REQUEST, loadReportSaga);
  yield takeLatest(LOAD_RECON_REQUEST, loadReconSaga);
  yield takeLatest(SAVE_RETURN_REQUEST, saveReturnSaga);
  yield takeLatest(CANCEL_RETURN_REQUEST, cancelReturnSaga);
  yield takeLatest(UPDATE_ACTIVITY_ROLE_REQUEST, updateActivityRoleSaga);
  yield takeLatest(ADD_NOTE_REQUEST, addNoteSaga);
  yield takeLatest(FETCH_ACTIVITY_LOGS_REQUEST, fetchActivityLogsSaga);
  yield takeLatest(AWAITING_APPROVAL_REQUEST, awaitingApprovalSaga);
  yield takeLatest(INTERNALLY_APPROVE_REQUEST, internallyApproveSaga);
  yield takeLatest(SEND_CLIENT_APPROVAL_REQUEST, sendClientApprovalSaga);
  yield takeLatest(CLIENT_APPROVE_REQUEST, clientApproveSaga);
  yield takeLatest(NOT_APPROVE_REQUEST, notApproveSaga);
  yield takeLatest(SUBMIT_RETURN_REQUEST, submitReturnSaga);
  yield takeLatest(LOCK_RETURN_REQUEST, lockReturnSaga);
  yield takeLatest(FETCH_EXCEL_FILE_REQUEST, fetchExcelFileSaga);
}
