import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

const IFrameWRapper = styled.div`
  height: calc(100vh - 75px);
`;

export const WhatsNewPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`What's New | ${process.env.PROJECT_NAME}`}</title>
        <meta
          name="description"
          content="This is a page for new developments"
        />
      </Helmet>
      <IFrameWRapper>
        <iframe
          src="https://scribehow.com/page-embed/generateTAX_product_releases__k6wWPOLOR6-6zAu5Eh457w"
          width="100%"
          height="100%"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </IFrameWRapper>
    </React.Fragment>
  );
};
