import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { ConfigProvider, Menu } from 'antd';
import routes from 'routes';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { ReactComponent as IconHome } from 'assets/images/icons/iconHome.svg';
import { ReactComponent as IconEntities } from 'assets/images/icons/iconEntities.svg';
import { ReactComponent as IconVIES } from 'assets/images/icons/iconVIES.svg';
import { ReactComponent as IconUsers } from 'assets/images/icons/iconUsers.svg';
import { ReactComponent as IconSupport } from 'assets/images/icons/iconSupport.svg';
import { ReactComponent as IconReporting } from 'assets/images/icons/iconReporting.svg';
import { ReactComponent as IconBilling } from 'assets/images/icons/iconBilling.svg';
import { MenuSize } from 'store/app/enums';
import { FileTextOutlined } from '@ant-design/icons';

const StyledMenuWide = styled(Menu)`
  position: relative;
  margin-top: 50px;
  font-weight: 600;
  .ant-menu-item-icon {
    color: #879494 !important;
    width: 20px !important;
    height: 20px !important;
  }
  .ant-menu-item-divider {
    border-color: #41aaaf;
    opacity: 25%;
    margin: 20px 0;
  }
  .ant-menu-item {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
`;

const StyledMenuNarrow = styled(Menu)`
  position: relative;
  margin-top: 50px;
  font-weight: 600;
  .ant-menu-item-icon {
    color: #879494 !important;
  }
  .ant-menu-item-divider {
    border-color: #41aaaf;
    opacity: 25%;
    margin: 20px 12px;
    width: 26px;
  }
  .ant-menu-item {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
  }
  .ant-menu-title-content {
    font-size: 10px;
    margin-inline-start: 0 !important;
    margin-top: -8px;
    white-space: normal;
    line-height: 14px;
    text-align: center;
    margin-top: 3px;
  }
`;

const items: MenuProps['items'] = [
  {
    label: 'Home',
    key: routes.main,
    icon: <IconHome />,
  },
  {
    label: 'Entities',
    key: routes.organisations.all,
    icon: <IconEntities />,
  },
  // {
  //   label: 'Reporting',
  //   key: routes.reporting,
  //   icon: <IconReporting />,
  // },
  // {
  //   label: 'Billing',
  //   key: routes.billing,
  //   icon: <IconBilling />,
  // },
  {
    label: 'VAT Checker',
    key: routes.vies.qualifiedEntities,
    icon: <IconVIES />,
  },
  {
    label: 'Users',
    key: routes.users,
    icon: <IconUsers />,
  },
  { type: 'divider' },
  {
    label: 'Support',
    key: routes.support,
    icon: <IconSupport />,
  },
  {
    label: "What's New",
    key: routes.whatsNew,
    icon: <FileTextOutlined />,
  },
];

interface SideMenuProps {
  menuSize: MenuSize;
  redirectPage: (route: string) => void;
}

export const SideMenu: React.FC<SideMenuProps> = ({
  redirectPage,
  menuSize,
}) => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  const onClick: MenuProps['onClick'] = (e) => {
    redirectPage(e.key);
  };

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBg: 'transparent',
            itemSelectedBg: '#ffffff',
            itemColor: '#042A2A',
            itemSelectedColor: '#042A2A',
            itemHoverBg: '#ffffff',
            itemHoverColor: '#41AAAF',
            activeBarBorderWidth: 0,
            itemBorderRadius: 8,
            itemHeight: 34,
            iconMarginInlineEnd: 20,
          },
        },
      }}
    >
      {menuSize === MenuSize.WIDE ? (
        <StyledMenuWide
          onClick={onClick}
          selectedKeys={[current]}
          mode="vertical"
          items={items}
        />
      ) : (
        <StyledMenuNarrow
          onClick={onClick}
          selectedKeys={[current]}
          mode="vertical"
          items={items}
        />
      )}
    </ConfigProvider>
  );
};
