import * as React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/downloadIcon.svg';
import DownloadCertificateIcon from 'assets/images/icons/downloadCertificate2Icon.svg';
import Logo from 'assets/images/generateTAX-logo.png';
import { IconButton } from 'components/Custom/buttons';
import { useTranslation } from 'react-i18next';
import { CenterLogo } from 'components/Custom/logo';
import { CardTitleLargeSpan, GreenSpan } from 'components/Custom/spans';
import { Link } from 'react-router-dom';
import { navigateToExternalPage } from 'utils/validation/common';
import { Contact } from 'store/vat-checker/models';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DownloadCertificateWrapper = styled.div`
  border: 1px solid #dbeae3;
  padding: 25px;
  margin: 10px 30px 15px;
  min-height: 561px;
  overflow: hidden;
  font-family: Inter;

  .error-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #d13131;
  }

  .report-link {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: #d13131;
  }
`;
interface DownloadCertificateProps {
  contact?: Contact;
  invoice: string;
  onClose: () => void;
}

const { Text } = Typography;
export const DownloadCertificate: React.FC<DownloadCertificateProps> = ({
  contact,
  invoice,
  onClose,
}) => {
  const { t } = useTranslation();

  const renderText = (contact: Contact) => {
    const date = moment(contact.lastViesCheck);
    return (
      <>
        The VAT Registration number <strong>{contact.taxNumber}</strong> for{' '}
        <strong>{contact.name}</strong> was verified through the EU Commission's
        VIES VAT Number Validation service on the{' '}
        <strong>{date.format('Do ')}</strong> of{' '}
        <strong>{date.format(' MMMM YYYY ')}</strong> at{' '}
        <strong>{date.format(' hh:mmA')}</strong>{' '}
        {invoice && (
          <>
            for <strong>{invoice}</strong>{' '}
          </>
        )}
        and was deemed to be <strong>valid</strong>
      </>
    );
    // const fullText = t('vat-checker.download-certificate-note')
    //   .replace('{company-name}', contact.name)
    //   .replace('{vat-number}', contact.taxNumber);
    // const steps = fullText.split('{certificate-date}');
    // const date = moment(contact.lastViesCheck);

    //   contact.lastViesCheck
    //     ? moment(contact.lastViesCheck).format('Do [of] MMMM YYYY [at] hh:mmA')
    //     : 'null'
    // );
    // return [
    //   steps[0],
    //   <>
    //     <strong>{date.format('Do ')}</strong>of
    //     <strong>{date.format(' MMMM YYYY ')}</strong>at
    //     <strong>{date.format(' hh:mmA')}</strong>
    //   </>,
    //   invoice ? (
    //     <>
    //       {' '}
    //       for <strong>{invoice}</strong>
    //     </>
    //   ) : (
    //     ''
    //   ),
    //   steps[1],
    // ];
  };
  return (
    <Row style={{ margin: '10px 0px' }}>
      <Col span={24} style={{ paddingRight: 30, marginTop: 6 }}>
        <IconButton //@ts-ignore
          width="108px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
            float: 'right',
          }}
          onClick={async () => {
            const getTargetElement = document.getElementById('certificate');
            const pdf = new jsPDF('p', 'mm', 'a4');
            html2canvas(getTargetElement, { scale: 2, useCORS: true }).then(
              (canvas) => {
                const imgWidth = 200;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const imgData = canvas.toDataURL('img/png');

                pdf.addImage(
                  imgData,
                  'PNG',
                  5,
                  20,
                  imgWidth, //Math.floor(img.width * 25.4),
                  imgHeight //Math.floor(300 * 25.4)
                );
                pdf.save(
                  `${t('vat-checker.vat-certificate')} ${contact?.name}.pdf`
                );
              }
            );
          }}
          icon={
            <DownloadIcon
              width={'16px'}
              height={'14px'}
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
              }}
            />
          }
          type="default"
        >
          {t('vat-checker.download')}
        </IconButton>
      </Col>
      <Col id="certificate" span={24}>
        <DownloadCertificateWrapper>
          <Row justify="center" style={{ paddingTop: '35px' }}>
            <Col span={24}>
              <Row justify="center">
                <CenterLogo src={Logo} width={140} height={30} />
              </Row>
              <Row
                justify="center"
                style={{ paddingTop: '30px', textAlign: 'center' }}
              >
                <CardTitleLargeSpan style={{ lineHeight: '24px' }}>
                  {`${t('vat-checker.vat-certificate')} ${contact?.name}`}
                </CardTitleLargeSpan>
              </Row>
              <Row justify="center" style={{ paddingTop: '10px' }}>
                <Link
                  onClick={() => navigateToExternalPage('www.generate.tax')}
                  to="#"
                >
                  <GreenSpan //@ts-ignore
                    fontSize="16px"
                    fontWeight="300"
                  >
                    www.generate.tax
                  </GreenSpan>
                </Link>
              </Row>
              <Divider
                style={{
                  width: '66px',
                  minWidth: '66px',
                  backgroundColor: '#41AAAF',
                  margin: '24px auto',
                }}
              />
            </Col>
            <Row justify="center" style={{ paddingTop: '10px' }}>
              <Text
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#042A2A',
                }}
              >
                {contact && renderText(contact)}
              </Text>
            </Row>
            <img
              id="certificate-background"
              src={DownloadCertificateIcon}
              style={{
                width: '440px',
                marginTop: '-25px',
                marginBottom: '-92px',
              }}
            />
            <Row justify="center">
              <Col style={{ position: 'absolute', top: '400px', left: '42%' }}>
                <Text
                  style={{
                    fontSize: '12px',
                    fontWeight: '700',
                    textAlign: 'center',
                    color: '#042A2A',
                  }}
                >
                  {contact?.name}
                </Text>
                <br />
                <Text
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    textAlign: 'center',
                    color: '#042A2A',
                  }}
                >
                  {contact?.address}
                </Text>
                <br />
                <Text
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    textAlign: 'center',
                    color: '#042A2A',
                  }}
                >
                  VAT: {contact?.taxNumber}
                </Text>
              </Col>
            </Row>
          </Row>
        </DownloadCertificateWrapper>
      </Col>
    </Row>
  );
};
