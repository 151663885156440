import moment from 'moment';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { VatReturnLog } from 'store/vat/models/vatReturnLog';
import { DataLogStatus } from 'store/app/enums';
import { ScopeButton } from 'components/Custom/buttons';
import { FormSelect } from 'components/Custom/selects';
import { ReactComponent as IconQuestionMark } from 'assets/images/icons/iconQuestionMark.svg';
import { FormDatePicker, FormRangePicker } from 'components/Custom/datePicker';
import { LabelWrapper } from 'components/Custom/labels';
import ECBlogo from 'assets/images/ECB-logo-small.png';
import styled from 'styled-components';

const VatOverviewWrapper = styled.div`
  .overview-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //height: 85vh;
  }
`;

interface VatOverviewProps {
  loading: boolean;
  prepLoading: boolean;
  vatReturnLogs: VatReturnLog[];
  currencies: string[];
  generateTaxReport: (dateRange: Date[], currency: string) => void;
  form: any;
  goBack: () => void;
  loadTaxReport: (dataLongId: string) => void;
  entityName?: string;
  taxReturnName?: string;
}
const { Text } = Typography;
//const { RangePicker } = FormDatePicker;

interface FieldType {
  dateRange: Date[];
  currency: string;
}

export const VatOverview: React.FC<VatOverviewProps> = ({
  loading,
  prepLoading,
  vatReturnLogs,
  currencies,
  generateTaxReport,
  form,
  goBack,
  loadTaxReport,
  entityName,
  taxReturnName,
}) => {
  const { t } = useTranslation();
  const [currencyValue, setCurrencyValue] = React.useState<string>();
  const changeCurrency = (value: string) => setCurrencyValue(value);
  const onChangeCurrency = (value: string) => {
    changeCurrency(value);
  };

  const handleSubmit = (values: FieldType) => {
    generateTaxReport(values.dateRange, values.currency);
  };

  return (
    <Spin spinning={loading} size="large">
      <Form
        layout={'horizontal'}
        onFinish={handleSubmit}
        form={form}
        //style={{ maxHeight: '100vh' }}
      >
        <VatOverviewWrapper>
          <div className="overview-main tax-submission-stretch">
            <div>
              <Col
                span={24}
                style={{
                  borderBottom: '1px solid #DBEAE3',
                  marginBottom: 30,
                  paddingBottom: 15,
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: 700 }}>
                  {entityName}
                </Text>
                <br />
                <Text style={{ fontSize: 16, fontWeight: 400 }}>
                  {taxReturnName}
                </Text>
              </Col>
              <Col span={24}>
                <LabelWrapper>
                  <span className="label-text">Choose Reporting Period</span>
                  <IconQuestionMark />
                </LabelWrapper>
                <Form.Item<FieldType>
                  name="dateRange"
                  rules={[
                    { required: true, message: t('signup.required-field') },
                  ]}
                >
                  <FormRangePicker //@ts-ignore
                    hasValue={true}
                    format="DD/MM/YYYY"
                    width="100%"
                  />
                </Form.Item>
                <LabelWrapper>
                  <span className="label-text">Reporting Currency</span>
                  <IconQuestionMark />
                </LabelWrapper>
                <Form.Item<FieldType>
                  name="currency"
                  rules={[
                    { required: true, message: t('signup.required-field') },
                  ]}
                >
                  <FormSelect //@ts-ignore
                    width="100%"
                    height="45px"
                    placeholder={'Select currency'}
                    optionFilterProp="children"
                    showSearch
                    value={currencyValue}
                    onChange={onChangeCurrency}
                    bordercolor="#DBEAE3"
                    bgcolor="#FFFFFF"
                    options={currencies.map((currency) => ({
                      label: currency,
                      value: currency,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            </div>
            <div>
              <Text style={{ fontSize: 11, fontWeight: 400 }}>
                {/* <strong>Create Tax Return:</strong> */}
                {/* <br /> */}
                The reporting period has been created based on your mapping. It
                is recommended to change the mapping instead of changing the
                reporting period. The period will change as soon as a previous
                period is Marked as Submitted.
              </Text>

              <div style={{ display: 'flex', gap: 10, margin: '30px 0px' }}>
                <img src={ECBlogo} />
                <Text style={{ fontSize: 11, fontWeight: 400 }}>
                  Most Euro foreign exchange reference rates provided by the
                  European Central Bank.
                </Text>
              </div>
              <Form.Item>
                <ScopeButton //@ts-ignore
                  width="100%"
                  type="primary"
                  htmlType="submit"
                >
                  Generate
                </ScopeButton>
              </Form.Item>
            </div>
          </div>
        </VatOverviewWrapper>
      </Form>
    </Spin>
  );
};
