import * as React from 'react';
import { VatReportData } from 'store/vat/models';
interface CyprusFormProps {
  data?: VatReportData;
  renderValueCell: (boxNumber: string) => void;
}
export const CyprusForm: React.FC<CyprusFormProps> = ({
  data,
  renderValueCell,
}) => {
  React.useEffect(() => {
    if (data === undefined || data === null) return;

    const currencySignSpans = document.querySelectorAll('.currency-sign');

    currencySignSpans.forEach((currencySignSpan) => {
      if (data.currencySymbol !== null) {
        currencySignSpan.textContent = data.currencySymbol;
      }
    });

    data?.sections.forEach((section, i) => {
      const sectionNameCell = document.querySelector(`#section10${i}-name`);
      if (sectionNameCell) {
        sectionNameCell.textContent = section.name;
      }
    });

    data?.subsections.forEach((subsection) => {
      const boxNumber = subsection.code?.replace(/^Box\s+/, '').toLowerCase();

      const subsectionNameCell = document.getElementById(
        `subsection${boxNumber}-name`
      );
      const subsectionBoxNameCell = document.getElementById(
        `subsection${boxNumber}-box-name`
      );

      const subsectionValueSpan = document.getElementById(
        `subsection${boxNumber}-value-span`
      );

      if (
        subsectionBoxNameCell !== null &&
        subsectionNameCell !== null &&
        subsectionValueSpan !== null
      ) {
        subsectionNameCell.textContent = subsection.name;
        subsectionBoxNameCell.textContent = subsection.code;

        const textContent: any = subsection.net;
        subsectionValueSpan.textContent = textContent.toFixed(2);
      }
    });
  }, [data]);

  return (
    <table className="vatreporttable table">
      <thead>
        <tr>
          <th id="section100-name">Vat Return</th>
          <th></th>
          <th style={{ width: 130, textAlign: 'center' }}>
            Value - <span className="currency-sign"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="subsection1-name">VAT due this period on outputs</td>
          <td id="subsection1-box-name" className="box">
            1
          </td>
          <td
            id="subsection1-value"
            className="rightalign value-field"
            tabIndex={1}
          >
            {renderValueCell('1')}
          </td>
        </tr>
        <tr>
          <td id="subsection2-name">
            VAT due this period on acquisitions from other Member States
          </td>
          <td id="subsection2-box-name" className="box">
            2
          </td>
          <td
            id="subsection2-value"
            className="rightalign value-field"
            tabIndex={2}
          >
            {renderValueCell('2')}
          </td>
        </tr>
        <tr>
          <td id="subsection3-name">Total VAT due (sum of boxes 1 and 2)</td>
          <td id="subsection3-box-name" className="box">
            3
          </td>
          <td
            id="subsection3-value"
            className="rightalign value-field"
            tabIndex={3}
          >
            {renderValueCell('3')}
          </td>
        </tr>
        <tr>
          <td id="subsection4-name">
            VAT recoverable this period on purchases and other inputs (including
            acquisitions from other Member States){' '}
          </td>
          <td id="subsection4-box-name" className="box">
            4
          </td>
          <td
            id="subsection4-value"
            className="rightalign value-field"
            tabIndex={4}
          >
            {renderValueCell('4')}
          </td>
        </tr>
        <tr>
          <td id="subsection5-name">
            VAT payable of refundable (Difference between boxes 3 and 4)
          </td>
          <td id="subsection5-box-name" className="box">
            5
          </td>
          <td
            id="subsection5-value"
            className="rightalign value-field"
            tabIndex={5}
          >
            {renderValueCell('5')}
          </td>
        </tr>
        <tr>
          <td id="subsection6-name">
            Total value of outputs (excluding VAT) (Including the amount in
            boxes 8A, 8B, 9, 10, 11A and 11B)
          </td>
          <td id="subsection6-box-name" className="box">
            6
          </td>
          <td
            id="subsection6-value"
            className="rightalign value-field"
            tabIndex={6}
          >
            {renderValueCell('6')}
          </td>
        </tr>
        <tr>
          <td id="subsection7-name">
            Total value of inputs (excluding VAT) (including the amount in boxes
            11A and 11B)
          </td>
          <td id="subsection7-box-name" className="box">
            7
          </td>
          <td
            id="subsection7-value"
            className="rightalign value-field"
            tabIndex={7}
          >
            {renderValueCell('7')}
          </td>
        </tr>
        <tr>
          <td id="subsection8a-name">
            Total value of all supplies of goods and related services (excluding
            VAT) to other Member States
          </td>
          <td id="subsection8a-box-name" className="box">
            8A
          </td>
          <td
            id="subsection8a-value"
            className="rightalign value-field"
            tabIndex={8}
          >
            {renderValueCell('8a')}
          </td>
        </tr>
        <tr>
          <td id="subsection8b-name">
            Total value of services provided to taxable persons in other Member
            States
          </td>
          <td id="subsection8b-box-name" className="box">
            8B
          </td>
          <td
            id="subsection8b-value"
            className="rightalign value-field"
            tabIndex={9}
          >
            {renderValueCell('8b')}
          </td>
        </tr>
        <tr>
          <td id="subsection9-name">
            Total value of outputs taxed at the rate of 0% (other than those
            included in box 8A)
          </td>
          <td id="subsection9-box-name" className="box">
            9
          </td>
          <td
            id="subsection9-value"
            className="rightalign value-field"
            tabIndex={10}
          >
            {renderValueCell('9')}
          </td>
        </tr>
        <tr>
          <td id="subsection10-name">
            Total value of sales which are outside the scope granting the right
            to recover input VAT (other than those included in box 8B)
          </td>
          <td id="subsection10-box-name" className="box">
            10
          </td>
          <td
            id="subsection10-value"
            className="rightalign value-field"
            tabIndex={11}
          >
            {renderValueCell('10')}
          </td>
        </tr>
        <tr>
          <td id="subsection11a-name">
            Total value of all acquisitions of goods and related services
            (excluding VAT) from other Member States
          </td>
          <td id="subsection11a-box-name" className="box">
            11A
          </td>
          <td
            id="subsection11a-value"
            className="rightalign value-field"
            tabIndex={12}
          >
            {renderValueCell('11a')}
          </td>
        </tr>
        <tr>
          <td id="subsection11b-name">
            Total value of services received from taxable persons in other
            Member States
          </td>
          <td id="subsection11b-box-name" className="box">
            11B
          </td>
          <td
            id="subsection11b-value"
            className="rightalign value-field"
            tabIndex={13}
          >
            {renderValueCell('11b')}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
