import { Col, Form, Row, Select } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { FormInput } from 'components/Custom/inputs';
import { FormSelect } from 'components/Custom/selects';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'store/auth/models';
import { CountryFlag, Timezone } from 'store/common/models';
import { renderPhoneCodeOption } from 'utils/render-countries-codes';

interface ContactDetailsFormProps {
  updateContact: (user: User) => void;
  form: any;
  timezones: Timezone[];
  countriesExternalData: CountryFlag[];
}

export const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  updateContact,
  form,
  timezones,
  countriesExternalData,
}) => {
  const { t } = useTranslation();
  const handleSaveDetails = (values) => {
    const user = values as User;
    user.phoneNumber = user['code']
      ? user['code'] + user.phoneNumber.replace('+', '')
      : user.phoneNumber;
    delete user['code'];
    updateContact(user);
  };
  return (
    <Form layout="vertical" onFinish={handleSaveDetails} form={form}>
      <Row gutter={24} justify={'space-between'} align={'bottom'}>
        <Col span={24}>
          <Form.Item
            name="firstName"
            label={t('signup.first-name')}
            rules={[
              {
                required: true,
                message: t('signup.required-field'),
              },
            ]}
          >
            <FormInput
              width="100%" //@ts-ignore
              border_color="#DBEAE3"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="lastName"
            label={t('signup.last-name')}
            rules={[
              {
                required: true,
                message: t('signup.required-field'),
              },
            ]}
          >
            <FormInput
              width="100%" //@ts-ignore
              border_color="#DBEAE3"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="companyName"
            label={t('signup.company-name')}
            rules={[
              {
                required: true,
                message: t('signup.required-field'),
              },
            ]}
          >
            <FormInput
              width="100%" //@ts-ignore
              border_color="#DBEAE3"
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ display: 'flex' }}>
          {/* <Form.Item
            name="code"
            rules={[{ required: false, message: t('signup.required-field') }]}
            style={{ width: '29%', marginRight: '2%' }}
          >
            <FormSelect //@ts-ignore
              width={'100%'}
              height="45px"
              placeholder={t('signup.code')}
              dropdownStyle={{ minWidth: '330px' }}
              optionLabelProp="value"
              id="counties-code"
              bgcolor="#FFFFFF"
            >
              {countriesExternalData &&
                countriesExternalData.map((c) => renderPhoneCodeOption(c))}
            </FormSelect>
          </Form.Item> */}
          <Form.Item
            label="Phone number"
            name="phoneNumber"
            rules={[{ required: true, message: t('signup.required-field') }]}
            style={{ width: '100%' }}
          >
            <FormInput width={'100%'} placeholder={t('signup.phone')} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="website" label="Website">
            <FormInput
              width="100%" //@ts-ignore
              border_color="#DBEAE3"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="email"
            label={t('signup.email')}
            rules={[
              {
                required: true,
                message: t('signup.required-field'),
              },
              {
                type: 'email',
                message: t('signup.invalid-email'),
              },
            ]}
          >
            <FormInput
              width="100%" //@ts-ignore
              border_color="#DBEAE3"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="timezone"
            label="Timezone"
            rules={[
              {
                required: true,
                message: t('signup.required-field'),
              },
            ]}
          >
            <FormSelect //@ts-ignore
              width="100%"
              bordercolor="#DBEAE3"
              height="45px"
            >
              {timezones &&
                timezones.map((tz, idx) => (
                  <Select.Option key={idx} value={tz.displayTimeZoneName}>
                    {tz.displayName}
                  </Select.Option>
                ))}
            </FormSelect>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <ScopeButton type="primary" htmlType="submit">
              {t('users.save-Details')}
            </ScopeButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
