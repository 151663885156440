import { takeLatest } from 'redux-saga/effects';
import {
  ADD_MEMBER_REQUEST,
  DELETE_USER_REQUEST,
  FETCH_USERS_REQUEST,
  FETCH_USER_HISTORY_REQUEST,
  FETCH_USER_RIGHTS_REQUEST,
  SET_ALL_USER_RIGHTS_REQUEST,
  SET_USER_PERMISSIONS_REQUEST,
  SET_USER_RIGHTS_REQUEST,
} from '../constants';

import { setUserPermissionsSaga } from './setUserPermissionsSaga';
import { addMemberSaga } from './addMemberSaga';
import { fetchUsersSaga } from './fetchUsersSaga';
import { deleteUserSaga } from './deleteUserSaga';
import { fetchUserHistorySaga } from './fetchUserHistorySaga';
import { fetchUserRightsSaga } from './fetchUserRightsSaga';
import { setUserRightsSaga } from './setUserRightsSaga';
import { setAllUserRightsSaga } from './setAllUserRightsSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* userSaga() {
  yield takeLatest(FETCH_USERS_REQUEST, fetchUsersSaga);
  yield takeLatest(SET_USER_PERMISSIONS_REQUEST, setUserPermissionsSaga);
  yield takeLatest(ADD_MEMBER_REQUEST, addMemberSaga);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(FETCH_USER_HISTORY_REQUEST, fetchUserHistorySaga);
  yield takeLatest(FETCH_USER_RIGHTS_REQUEST, fetchUserRightsSaga);
  yield takeLatest(SET_USER_RIGHTS_REQUEST, setUserRightsSaga);
  yield takeLatest(SET_ALL_USER_RIGHTS_REQUEST, setAllUserRightsSaga);
}
