import { call, put } from 'redux-saga/effects';
import {
  addNoteFailure,
  addNoteSuccess,
  fetchActivityLogsRequest,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { AddNoteRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { addNote } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* addNoteSaga(action: AddNoteRequestAction) {
  try {
    let err: ApiError | null;
    const { note, dataLogId } = action.payload;

    const response: ApiResponse = yield call(addNote, note, dataLogId);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success !',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    yield put(fetchActivityLogsRequest(dataLogId));
    yield put(addNoteSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(addNoteFailure());
  }
}
