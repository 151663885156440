import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Select, Spin } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { ReturnFrequency } from 'store/api/enums';
import { calculateNextPeriod } from 'utils/calculate-next-period';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReturnData,
  getReturnLoading,
  getReturnPreLoading,
  getReturnTaxRatesLoading,
} from 'store/returns/selectors';
import { FormSelect } from 'components/Custom/selects';
import { ReturnType } from 'store/returns/models/returnData';
import { ReactComponent as IconQuestionMark } from 'assets/images/icons/iconQuestionMark.svg';
import styled from 'styled-components';
import { FormDatePicker } from 'components/Custom/datePicker';
import { ScopeButton } from 'components/Custom/buttons';
import { MappingData } from 'store/returns/models';
import { getMappingData } from 'store/app/selectors';
import { turnDrawerOff, updateMappingData } from 'store/app/actions';
import routes from 'routes';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';
import { LabelWrapper } from 'components/Custom/labels';

const DescriptionBox = styled.div`
  border-radius: 5px;
  border: 1px solid #dbeae3;
  opacity: 0px;
  background: #fff5e2;
  margin: -18px 0 20px 0;
  padding: 10px 15px;
  font-size: 11px;
  line-height: 13px;
  text-align: left;
  min-height: 45px;
`;

const PeriodBox = styled.div`
  height: 45px;
  border: 1px solid #dbeae3;
  color: #042a2a;
  font-weight: 600;
  background: #f3f7f8;
  padding: 10px 15px;
`;

const NoteText = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;
interface ChooseReturnProps {
  editMode: boolean;
  setSectionTouched: (value: boolean) => void;
}

interface Country {
  countryId: string;
  countryName: string;
}

type FieldType = {
  country?: string;
  returnType?: string;
  returnFromDate?: Date;
  returnDueDate?: Date;
  returnFrequency?: ReturnFrequency;
  nextPeriod?: string;
};

enum FieldNames {
  COUNTRY = 'country',
  RETURN_TYPE = 'returnType',
  FREQUENCY = 'returnFrequency',
  FROM_DATE = 'returnFromDate',
  DUE_DATE = 'returnDueDate',
}

export const ChooseReturn: React.FC<ChooseReturnProps> = ({
  editMode,
  setSectionTouched,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [endDate, setEndDate] = useState<Date>(new Date('1900-01-01'));
  const [countries, setCountries] = useState<Country[]>([]);
  const [returnTypes, setReturnTypes] = useState<ReturnType[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedFrequency, setSelectedFrequency] = useState<ReturnFrequency>();
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedSubmissionDate, setSelectedSubmissionDate] = useState<any>();
  const [nextPeriod, setNextPeriod] = useState<string>();
  const mappingData = useSelector(getMappingData);
  const returnData = useSelector(getReturnData);
  const preLoading = useSelector(getReturnPreLoading);
  const taxRatesLoading = useSelector(getReturnTaxRatesLoading);
  const loading = useSelector(getReturnLoading);
  const returnFrequency = returnData?.returnFrequency;
  const onSetEndDate = (endDate: Date) => {
    setEndDate(endDate);
  };

  const onFrequencyChange = (frequency: ReturnFrequency) => {
    form.validateFields([[FieldNames.FREQUENCY]]);
    if (!!selectedSubmissionDate && !!selectedStartDate) {
      if (selectedSubmissionDate.isAfter(selectedStartDate)) {
        form.resetFields(['returnDueDate']);
        setSelectedSubmissionDate(undefined);
      }
    }

    setSelectedFrequency(frequency);
  };
  const onStartDateChange = (date: any) => {
    form.validateFields([[FieldNames.FROM_DATE]]);

    if (date.isAfter(selectedStartDate)) {
      form.resetFields(['returnDueDate']);
      setSelectedSubmissionDate(undefined);
    }
    setSelectedStartDate(date);
  };
  const onSubmissionDateChange = (date: any) => {
    form.validateFields([[FieldNames.DUE_DATE]]);
    setSelectedSubmissionDate(date);
  };
  useEffect(() => {
    let retType;
    if (mappingData?.mappingId) retType = returnData?.returnTypesEdit;
    else retType = returnData?.returnTypes;
    if (retType) {
      const cntrs: Country[] = [];
      retType.forEach((rt: ReturnType) => {
        rt.countryId &&
          cntrs.findIndex((c) => c.countryId === rt.countryId) === -1 &&
          cntrs.push({ countryId: rt.countryId, countryName: rt.countryName });
      });
      cntrs?.sort((a, b) => {
        if (a.countryName > b.countryName) {
          return 1;
        }

        if (a.countryName < b.countryName) {
          return -1;
        }

        return 0;
      });
      setCountries(cntrs);
    }
  }, [returnData]);

  const onCountryChange = (countryId?: string) => {
    const retTypes = mappingData?.mappingId
      ? returnData?.returnTypesEdit
      : returnData?.returnTypes;
    if (retTypes) {
      const newReturnTypes: ReturnType[] = retTypes.filter(
        (rt) => rt.countryId === countryId
      );
      form.validateFields([[FieldNames.COUNTRY]]);
      setSelectedCountry(countryId);
      setSelectedType(undefined);
      form.setFieldsValue({
        ['returnType']: undefined,
      });
      setReturnTypes(newReturnTypes);
    }
  };

  useEffect(() => {
    if (selectedStartDate && selectedFrequency) {
      const nextPeriod: any[] = calculateNextPeriod(
        selectedStartDate,
        selectedFrequency
      );
      const dEndDate = new Date(nextPeriod[1]);
      onSetEndDate(dEndDate);
      setTimeout(function () {}, 1000);
      setNextPeriod(nextPeriod[0]);
    }
  }, [selectedFrequency, selectedStartDate]);

  const dueDateDisabled = () =>
    endDate.valueOf() === new Date('1900-01-01').valueOf();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current.valueOf() < endDate.valueOf() + 60000 * 60 * 24;
  };

  const onReturnTypeChange = (returnType?: string) => {
    const selectedType = returnTypes.find((rt) => rt.id === returnType);
    //sortSelectionByReturnType(returnType);
    form.validateFields([[FieldNames.RETURN_TYPE]]);
    setSelectedType(selectedType?.id);
  };
  const handleSubmit = () => {
    const mappingId =
      mappingData?.mappingId && !editMode ? undefined : mappingData?.mappingId;
    const jsonMapping =
      mappingData?.mappingId && !editMode
        ? undefined
        : mappingData?.jsonMapping;
    const typeId = selectedType;
    const returnFrequency = selectedFrequency;
    const countryId = selectedCountry;
    const periodFromDate = moment(new Date(selectedStartDate)).format(
      'YYYY-MM-DD'
    );

    const returnDueDate = moment(new Date(selectedSubmissionDate)).format(
      'YYYY-MM-DD'
    );

    const md: MappingData = {
      ...mappingData,
      typeId,
      periodFromDate,
      returnDueDate,
      returnFrequency,
      countryId,
      mappingId,
      jsonMapping,
    };
    dispatch(updateMappingData(md));

    if (editMode && mappingData?.mappingId) {
      setSectionTouched(true);
      dispatch(turnDrawerOff());
    } else if (returnData?.connectionId) {
      dispatch(
        push(
          routes.returns.add.replace(':connectionId', returnData?.connectionId)
        )
      );
      dispatch(turnDrawerOff());
    }
    //updateMappingData(md);
    // passed = true;
    // onSetCurrent(current + 1);
    // loadTemplateMappings();
  };
  useEffect(() => {
    if (mappingData && editMode) {
      const nextPeriod: any = calculateNextPeriod(
        mappingData.periodFromDate,
        mappingData.returnFrequency ? mappingData.returnFrequency : ''
      );

      setEndDate(new Date(nextPeriod[1]));
      onCountryChange(mappingData.countryId);

      setSelectedType(mappingData.typeId);
      setSelectedFrequency(mappingData?.returnFrequency);
      setSelectedStartDate(dayjs(mappingData?.periodFromDate));
      setSelectedSubmissionDate(dayjs(mappingData?.returnDueDate));
      form.setFieldsValue({
        ['country']: mappingData.countryId,
        ['returnType']: mappingData.typeId,
        ['returnFrequency']: mappingData.returnFrequency
          ? mappingData.returnFrequency
          : '',
        ['returnFromDate']: dayjs(mappingData?.periodFromDate),
        ['nextPeriod']: nextPeriod[0],
        ['returnDueDate']: dayjs(mappingData?.returnDueDate),
      });
    }

    // if (editMode && mappingData) {
    //   onCountryChange(mappingData.countryId);
    //   form.setFieldsValue({
    //     ['country']: mappingData.countryId,
    //     ['returnType']: mappingData.typeId,
    //     ['returnFrequency']: mappingData.returnFrequency,
    //     ['returnFromDate']: dayjs(mappingData.periodFromDate),
    //     ['returnDueDate']: dayjs(mappingData.returnDueDate),
    //   });
    // }
  }, [mappingData]);
  return (
    <Spin spinning={!!preLoading}>
      <Row justify={'center'}>
        <Col span={24}>
          <Form
            name="basic"
            form={form}
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <LabelWrapper>
              <span className="label-text">{t('returns.mapping.country')}</span>
              <IconQuestionMark />
            </LabelWrapper>
            <Form.Item<FieldType>
              name="country"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedCountry) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormSelect //@ts-ignore
                width="100%"
                height="45px"
                placeholder={t('common.select')}
                onChange={onCountryChange}
                value={selectedCountry}
                disabled={mappingData && !!mappingData.mappingId && editMode}
                bordercolor="#DBEAE3"
                bgcolor="#FFFFFF"
              >
                {countries &&
                  countries.map((c) => (
                    <Select.Option key={c.countryId} value={c.countryId}>
                      {c.countryName}
                    </Select.Option>
                  ))}
              </FormSelect>
            </Form.Item>
            <LabelWrapper>
              <span className="label-text">
                {t('returns.mapping.tax-return')}
              </span>
              <IconQuestionMark />
            </LabelWrapper>
            <Form.Item<FieldType>
              name="returnType"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedType) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormSelect
                placeholder={t('common.select')}
                onChange={onReturnTypeChange}
                disabled={mappingData && !!mappingData.mappingId && editMode} //@ts-ignore
                width="100%"
                height="45px"
                bordercolor="#DBEAE3"
                bgcolor="#FFFFFF"
              >
                {returnTypes?.map((rt) => (
                  <Select.Option key={rt.id} value={rt.id}>
                    <span
                      key={rt.id}
                      style={editMode ? { color: '#2E2E2E' } : {}}
                    >
                      {rt.name}
                    </span>
                  </Select.Option>
                ))}
              </FormSelect>
            </Form.Item>
            {selectedType && (
              <DescriptionBox>
                <strong>
                  {`${returnTypes
                    .find((rt) => rt.id === selectedType)
                    ?.name.trim()}: `}
                </strong>
                {returnTypes.find((rt) => rt.id === selectedType)?.description}
              </DescriptionBox>
            )}
            <LabelWrapper>
              <span className="label-text">
                {t('returns.mapping.return-frequency')}
              </span>
              <IconQuestionMark />
            </LabelWrapper>
            <Form.Item<FieldType>
              name="returnFrequency"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedFrequency) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormSelect
                placeholder={t('common.select')}
                onChange={onFrequencyChange} //@ts-ignore
                width="100%"
                height="45px"
                bordercolor="#DBEAE3"
                bgcolor="#FFFFFF"
              >
                {returnFrequency &&
                  Object.keys(returnFrequency).map((rf) => (
                    <Select.Option key={rf} value={rf}>
                      {ReturnFrequency[rf]}
                    </Select.Option>
                  ))}
              </FormSelect>
            </Form.Item>
            <LabelWrapper>
              <span className="label-text">
                {t('returns.mapping.return-start-date')}
              </span>
              <IconQuestionMark />
            </LabelWrapper>
            <Form.Item<FieldType>
              name="returnFromDate"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedStartDate) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormDatePicker
                style={{ width: '100%' }}
                placeholder={t('common.select')}
                onChange={onStartDateChange}
                format="DD/MM/YYYY" //@ts-ignore
                hasValue={!!selectedStartDate}
              />
            </Form.Item>
            <LabelWrapper>
              <span className="label-text">
                {t('returns.mapping.period-chosen')}
              </span>
              <IconQuestionMark />
            </LabelWrapper>
            <Form.Item<FieldType> name="nextPeriod">
              <PeriodBox>{nextPeriod}</PeriodBox>
            </Form.Item>
            <LabelWrapper>
              <span className="label-text">
                {t('returns.mapping.return-due-date')}
              </span>
              <IconQuestionMark />
            </LabelWrapper>
            <Form.Item<FieldType>
              name="returnDueDate"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedSubmissionDate) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormDatePicker
                style={{ width: '100%' }}
                placeholder={t('common.select')}
                format="DD/MM/YYYY"
                onChange={onSubmissionDateChange}
                disabledDate={disabledDate}
                disabled={dueDateDisabled()} //@ts-ignore
                hasValue={!!selectedSubmissionDate}
              />
            </Form.Item>
            <Form.Item>
              <ScopeButton
                type="primary"
                htmlType="submit"
                loading={taxRatesLoading || loading} //@ts-ignore
                width="100%"
              >
                {editMode && mappingData?.mappingId
                  ? t('common.update')
                  : t('common.continue')}
              </ScopeButton>
            </Form.Item>
            <NoteText>
              <strong>Note:</strong> If you are interested to extend this
              functionality in your country, or to request additional tax
              returns, get in touch with the Support team or with your account
              manager
            </NoteText>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
