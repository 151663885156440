import { call, put } from 'redux-saga/effects';
import { confirmUserFailure, confirmUserSuccess } from '../actions';
import { ConfirmResponse, confirmUser } from '../api';
import { ConfirmUserRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { MessageType } from 'store/api/enums';
import { logoutRequest } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* confirmUserSaga(action: ConfirmUserRequestAction) {
  try {
    let err: ApiError | null;
    const { verificationKey, passwordReset } = action.payload;
    const response: ConfirmResponse = yield call(confirmUser, verificationKey);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    if (
      response.responseMessage.messageType === MessageType.INFO &&
      !passwordReset
    ) {
      const message: MessageData = {
        title: 'Server mesage',
        description: response.responseMessage.message,
        type: MessageStates.INFO,
      };
      yield put(turnMessageOn(message));
      yield put(logoutRequest());
    } else yield put(confirmUserSuccess(response?.data));
  } catch (error: any) {
    yield put(confirmUserFailure());
  }
}
