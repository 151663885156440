import { Col, Row } from 'antd';
import { EntityButton } from 'components/Custom/buttons';
import React from 'react';
import { ReactComponent as LogoXero } from 'assets/images/entityButtonXero.svg';
import { ReactComponent as LogoCin7 } from 'assets/images/entityButtonCin7.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from 'store/api/enums';
import { getAuthUser } from 'store/auth/selectors';
import routes from 'routes';
import { fetchAddUrlRequest } from 'store/organisation/actions';
import { turnDrawerOff, turnDrawerOn } from 'store/app/actions';
import { DrawerData } from 'store/app/types';
import { DrawerType } from 'store/app/enums';
import { ConnectCin7 } from 'components/Entity/connect-cin7';

export const ConnectEntity: React.FC = () => {
  const loggedUser = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const onConnectCin7 = () => {
    const drawerData: DrawerData = {
      title: 'Connect Cin7',
      type: DrawerType.CONNECT_CIN7,
      component: <ConnectCin7 />,
    };
    dispatch(turnDrawerOn(drawerData));
  };
  const connectOrganisation = (providerName: Provider) => {
    const sourceUrl = location.pathname ? location.pathname : routes.main;
    if (loggedUser) {
      dispatch(
        fetchAddUrlRequest(providerName, loggedUser.userUuid, sourceUrl)
      );
      // clearing cache
      localStorage.removeItem(`gen-tax-tax-rates-${loggedUser?.userUuid}`);
      localStorage.removeItem(`vies-infos-${loggedUser?.userUuid}`);

      dispatch(turnDrawerOff());
    }
  };
  return (
    <Row
      justify={'space-between'}
      gutter={[0, 15]}
      style={{ marginTop: '20px' }}
    >
      <Col>
        <EntityButton onClick={() => connectOrganisation(Provider.XERO)}>
          <LogoXero />
        </EntityButton>
      </Col>
      <Col>
        <EntityButton onClick={() => onConnectCin7()}>
          <LogoCin7 />
        </EntityButton>
      </Col>
      {/* <Col>
        <EntityButton>
          <LogoZoho />
        </EntityButton>
      </Col> */}
    </Row>
  );
};
