import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';
import { ReturnType, Section, Subsection, TaxRate } from './models/returnData';
import { Mapping } from 'store/organisation/models';
import { MappingData } from './models';
import { ReturnFrequency } from 'store/api/enums';

export interface GetReturnTypesResponse extends ApiResponse {
  data: { returnTypes: ReturnType[]; returnTypesEdit: ReturnType[] };
}
export const getReturnTypes = (
  connectionId: string
): Promise<GetReturnTypesResponse> =>
  api.get<GetReturnTypesResponse>(`/auth/returns/${connectionId}/returnTypes`);

export interface GetTemplatesResponse extends ApiResponse {
  data: { templates: Mapping[] };
}
export const getTemplates = (): Promise<GetTemplatesResponse> =>
  api.get<GetTemplatesResponse>(`/auth/returns/templates`);

export interface GetReturnFrequencyResponse extends ApiResponse {
  data: { frequencies: ReturnFrequency };
}
export const getFrequency = (): Promise<GetReturnFrequencyResponse> =>
  api.get<GetReturnFrequencyResponse>(`/auth/returns/frequency`);

export interface GetSectionsResponse extends ApiResponse {
  data: { sections: Section[]; subsections: Subsection[] };
}
export const getSections = (
  connectionId: string
): Promise<GetSectionsResponse> =>
  api.get<GetSectionsResponse>(
    `/auth/returns/${connectionId}/sectionsAndSubsections`
  );

export interface GetTaxRatesResponse extends ApiResponse {
  taxRates: TaxRate[];
  connectionId: string;
}
export const getTaxRates = (
  connectionId: string
): Promise<GetTaxRatesResponse> =>
  api.get<GetTaxRatesResponse>(`/auth/returns/${connectionId}/taxRates`);

export interface PostMappingResponse extends ApiResponse {
  data: { mapping: Mapping };
}
export const postMapping = (
  connectionId: string,
  mappingData: MappingData
): Promise<PostMappingResponse> => {
  const newJSON = { mapping: mappingData.jsonMapping };
  return api.post<PostMappingResponse>(
    `/auth/returns/${connectionId}/saveMapping`,
    {
      body: {
        ...mappingData,
        jsonMapping: JSON.stringify(newJSON),
      },
    }
  );
};

export const deleteMapping = (
  organisationId: string,
  mappingId: string
): Promise<ApiResponse> => {
  return api.delete<ApiResponse>(
    `/auth/returns/organisation/${organisationId}/mapping/${mappingId}/delete`
  );
};
