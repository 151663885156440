import { call, put } from 'redux-saga/effects';
import { registerFailure, registerSuccess } from '../actions';
import { register } from '../api';
import { RegisterRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { push } from 'connected-react-router';
import routes from 'routes';
import { fetchUsersRequest } from 'store/user/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* registerSaga(action: RegisterRequestAction) {
  try {
    let err: ApiError | null;

    const { user, gCaptchaResponse, ssoSignUp } = action.payload;
    const response = yield call(register, user, gCaptchaResponse);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    if (ssoSignUp) {
      yield put(fetchUsersRequest());
      yield put(push(routes.main));
    } else yield put(push(routes.signUp.signupSuccess));

    yield put(registerSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(registerFailure());
  }
}
