import { call, put } from 'redux-saga/effects';
import { fetchExcelFileFailure, fetchExcelFileSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { FetchExcelFileRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { ExportedExcelFileResponse, getExcelFileForExport } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchExcelFileSaga(action: FetchExcelFileRequestAction) {
  try {
    let err: ApiError | null;
    const { reportTypeId } = action.payload;
    const response: ExportedExcelFileResponse = yield call(
      getExcelFileForExport,
      reportTypeId
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    yield put(fetchExcelFileSuccess(response.data));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(fetchExcelFileFailure());
  }
}
