import { Provider } from 'store/api/enums';
import { ViesCheck } from 'store/vat-checker/models';

export const getInvoiceLink = (viesLog: ViesCheck, providerName: string) => {
  let link: string | null = '';
  if (providerName === Provider.CIN7) {
    link = `https://inventory.dearsystems.com/Sale#${viesLog.triggerInvoiceId}`;
  } else if (providerName.toLowerCase() === Provider.XERO) {
    link = viesLog.sourceUrl;
  }
  return link;
};
