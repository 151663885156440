import { createAction } from 'store/utils';
import {
  ConnectCin7EntityFailureAction,
  ConnectCin7EntityRequestAction,
  ConnectCin7EntitySuccessAction,
  DisconnectOrganisationFailureAction,
  DisconnectOrganisationRequestAction,
  DisconnectOrganisationSuccessAction,
  EditOrganisationFailureAction,
  EditOrganisationRequestAction,
  EditOrganisationSuccessAction,
  FetchAddUrlFailureAction,
  FetchAddUrlRequestAction,
  FetchAddUrlSuccessAction,
  FetchOrganisationsFailureAction,
  FetchOrganisationsRequestAction,
  FetchOrganisationsSuccessAction,
  //PostSaveRefreshMappingDataAction,
  ResetOrganisationDataAction,
  UpdateOrganisationDataAction,
} from './types';
import {
  CONNECT_CIN7_ENTITY_FAILURE,
  CONNECT_CIN7_ENTITY_REQUEST,
  CONNECT_CIN7_ENTITY_SUCCESS,
  DISCONNECT_ORGANISATION_FAILURE,
  DISCONNECT_ORGANISATION_REQUEST,
  DISCONNECT_ORGANISATION_SUCCESS,
  EDIT_ORGANISATION_FAILURE,
  EDIT_ORGANISATION_REQUEST,
  EDIT_ORGANISATION_SUCCESS,
  FETCH_ADD_URL_FAILURE,
  FETCH_ADD_URL_REQUEST,
  FETCH_ADD_URL_SUCCESS,
  FETCH_ORGANISATIONS_FAILURE,
  FETCH_ORGANISATIONS_REQUEST,
  FETCH_ORGANISATIONS_SUCCESS,
  //POST_SAVE_REFRESH_MAPPING_DATA,
  RESET_ORGANISATION_DATA,
  SET_GETTING_TAXES,
  UPDATE_ENTITY_MEMBERS,
  UPDATE_ORGANISATION_DATA,
  UPDATE_SUBMISSION_LOG,
} from './constants';
import { EditOrganisationParams, Mapping, Organisation } from './models';
import { MappingData } from 'store/returns/models';
import { TablePermission } from 'store/api/enums';
import { User } from 'store/auth/models';
import { SubmissionUpdateField } from 'store/app/enums';

// Fetch Organisations
export const fetchOrganisationsRequest = (): FetchOrganisationsRequestAction =>
  createAction(FETCH_ORGANISATIONS_REQUEST);

export const fetchOrganisationsSuccess = (
  organisations: Organisation[],
  mappings: Mapping[]
): FetchOrganisationsSuccessAction =>
  createAction(FETCH_ORGANISATIONS_SUCCESS, { organisations, mappings });

export const fetchOrganisationsFailure = (): FetchOrganisationsFailureAction =>
  createAction(FETCH_ORGANISATIONS_FAILURE);

// export const postSaveRefreshMappinData = (
//   mappingData: MappingData
// ): PostSaveRefreshMappingDataAction =>
//   createAction(POST_SAVE_REFRESH_MAPPING_DATA, { mappingData });

// DIsconnect Organisations
export const disconnectOrganisationsRequest = (
  organisationId: string,
  fromVIES = false
): DisconnectOrganisationRequestAction =>
  createAction(DISCONNECT_ORGANISATION_REQUEST, { organisationId, fromVIES });

export const disconnectOrganisationsSuccess =
  (): DisconnectOrganisationSuccessAction =>
    createAction(DISCONNECT_ORGANISATION_SUCCESS);

export const disconnectOrganisationsFailure =
  (): DisconnectOrganisationFailureAction =>
    createAction(DISCONNECT_ORGANISATION_FAILURE);

// Edit Organisations
export const editOrganisationsRequest = (
  editOrganisation: EditOrganisationParams
): EditOrganisationRequestAction =>
  createAction(EDIT_ORGANISATION_REQUEST, { editOrganisation });

export const editOrganisationsSuccess = (): EditOrganisationSuccessAction =>
  createAction(EDIT_ORGANISATION_SUCCESS);

export const editOrganisationsFailure = (): EditOrganisationFailureAction =>
  createAction(EDIT_ORGANISATION_FAILURE);

// Fetch Add Url
export const fetchAddUrlRequest = (
  providerName: string,
  uuid: string,
  sourceUrl: string
): FetchAddUrlRequestAction =>
  createAction(FETCH_ADD_URL_REQUEST, { providerName, uuid, sourceUrl });

export const fetchAddUrlSuccess = (): FetchAddUrlSuccessAction =>
  createAction(FETCH_ADD_URL_SUCCESS);

export const fetchAddUrlFailure = (): FetchAddUrlFailureAction =>
  createAction(FETCH_ADD_URL_FAILURE);

// Update members
export const updateEntityMembers = (
  organisationId: string,
  member: User,
  tablePermission: TablePermission
) =>
  createAction(UPDATE_ENTITY_MEMBERS, {
    organisationId,
    member,
    tablePermission,
  });

// Fetch Add Url
export const connectCin7EntityRequest = (
  key: string,
  cin7accountId: string,
  orgName: string
): ConnectCin7EntityRequestAction =>
  createAction(CONNECT_CIN7_ENTITY_REQUEST, { key, cin7accountId, orgName });

export const connectCin7EntitySuccess = (): ConnectCin7EntitySuccessAction =>
  createAction(CONNECT_CIN7_ENTITY_SUCCESS);

export const connectCin7EntityFailure = (): ConnectCin7EntityFailureAction =>
  createAction(CONNECT_CIN7_ENTITY_FAILURE);

// Update submission log
export const updateSubmissionLog = (
  mappingId: string,
  submissionUpdateField: SubmissionUpdateField,
  data: any,
  dataLogId?: string
) =>
  createAction(UPDATE_SUBMISSION_LOG, {
    mappingId,
    submissionUpdateField,
    data,
    dataLogId,
  });

// Getting Taxes
export const setGettingTaxes = (gettingTaxes: boolean) =>
  createAction(SET_GETTING_TAXES, {
    gettingTaxes,
  });

// RESET
export const resetOrganisationData = (): ResetOrganisationDataAction =>
  createAction(RESET_ORGANISATION_DATA);

// RESET
export const updateOrganisationData = (
  entityId: string,
  vatCheckerActive: boolean
): UpdateOrganisationDataAction =>
  createAction(UPDATE_ORGANISATION_DATA, { entityId, vatCheckerActive });
