import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { logoutFailure, logoutSuccess } from '../actions';
import { logout } from '../api';
import routes from 'routes';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { resetAccountData } from 'store/account/actions';
import { resetOrganisationData } from 'store/organisation/actions';
import { resetReturnData } from 'store/returns/actions';
import { resetUserData } from 'store/user/actions';
import { resetVatData } from 'store/vat/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* logoutSaga() {
  try {
    yield put(push(routes.login));
    yield call(logout);

    yield put(resetAccountData());
    yield put(resetOrganisationData());
    yield put(resetReturnData());
    yield put(resetUserData());
    yield put(resetVatData());
    yield put(logoutSuccess());
    //localStorage.setItem('loggedOut', 'true');
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(logoutFailure());
  }
}
