import { ConfigProvider, Table, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginDetails } from 'store/user/models';
import { ScopeButton } from 'components/Custom/buttons';
import moment from 'moment';
import { CardTitleSpan } from 'components/Custom/spans';
import { EnvironmentOutlined } from '@ant-design/icons';

interface HistoryProps {
  history?: LoginDetails[];
  onClose: () => void;
  profileMode?: boolean;
}

export const History: React.FC<HistoryProps> = ({
  history,
  onClose,
  profileMode,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('users.login-date'),
      dataIndex: 'loginTimestamp',
      key: 'loginTimestamp',
      render: (_, record: LoginDetails) =>
        moment(record.loginTimestamp).format('DD-MM-YYYY hh:mm:ss'),
    },
    {
      title: t('users.ip-address'),
      dataIndex: 'loginIP',
      key: 'loginIP',
    },
    {
      title: '',
      render: (_, record: LoginDetails) => (
        <a
          target="_blank"
          href={`https://whatismyipaddress.com/ip/${record.loginIP}`}
        >
          <EnvironmentOutlined />{' '}
        </a>
      ),
    },
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: '#E9F6F7',
            borderColor: '#DBEAE3',
          },
        },
      }}
    >
      {profileMode && <CardTitleSpan>{t('users.login-history')}</CardTitleSpan>}
      <Typography
        style={{
          fontSize: profileMode ? '12px' : '16px',
          fontWeight: '400',
          lineHeight: '24px',
          textAlign: 'left',
          color: '#879494',
          marginBottom: '40px',
        }}
      >
        {t('users.login-history-desc')}
      </Typography>
      <Table
        bordered={true}
        size="small"
        dataSource={history}
        columns={columns}
        rowKey={(record) => record.loginTimestamp.toString()}
        pagination={{
          pageSize: profileMode ? 10 : 8,
          hideOnSinglePage: true,
        }}
      />
      {!profileMode && (
        <div style={{ marginTop: '40px' }}>
          <ScopeButton
            type="default" //@ts-ignore
            width="100%"
            height="40px"
            bg_color="#E9F6F7"
            onClick={() => onClose()}
          >
            {t('common.close')}
          </ScopeButton>
        </div>
      )}
    </ConfigProvider>
  );
};
