import { VatReport } from 'components/Vat/Report';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import routes from 'routes';
import {
  addNoteRequest,
  awaitingApprovalRequest,
  cancelReturnRequest,
  clientApproveRequest,
  fetchActivityLogsRequest,
  fetchExcelFileRequest,
  fetchViewRunDataRequest,
  generateReportRequest,
  internallyApproveRequest,
  loadReconRequest,
  loadReportRequest,
  lockReturnRequest,
  notApproveRequest,
  saveReturnRequest,
  sendClientApprovalRequest,
  submitReturnRequest,
  updateActivityRoleRequest,
} from 'store/vat/actions';
import {
  SaveReturnRequestData,
  VatGenerateRequestData,
  VatRunData,
} from 'store/vat/models';
import {
  getExcelFileData,
  getVatActivityLogs,
  getVatLoading,
  getVatPrepLoading,
  getVatReconciliation,
  getVatReportData,
  getVatRunData,
  getVatSuccess,
} from 'store/vat/selectors';
import { getAuthUser } from 'store/auth/selectors';
import { Spin } from 'antd';
import {
  ActivityRoles,
  DataLogActions,
  DataLogStatus,
  DrawerType,
  VatTaxReportType,
  WindowType,
} from 'store/app/enums';
import {
  ActivityRoleUpdateRequest,
  JSONTotals,
  VatLoadRequestData,
  ViewRunRequestData,
} from 'store/vat/models/requestData';
import {
  turnDrawerOn,
  turnMessageOn,
  turnModalOff,
  turnModalOn,
  updateMappingData,
} from 'store/app/actions';
import { MessageStates } from 'containers/MessageBox';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import { ClientApproveForm } from 'components/Vat/Report/client-approve-form';
import { EmailDataParametersRequest } from 'store/vat/models/emailDataParametersRequest';
import { NotApproveForm } from 'components/Vat/Report/not-approve-form';
import { getMappingData, getMenuSize } from 'store/app/selectors';
import {
  getOrgMappedOrganisations,
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import { useTranslation } from 'react-i18next';
import { getUserMembers } from 'store/user/selectors';
import { ConnectEntity } from 'components/ConnectEntity';
import { getReturnData, getReturnPreLoading } from 'store/returns/selectors';
import { SelectedRate, TaxRateOption } from 'pages/returns';
import { Section } from 'store/returns/models/returnData';
import { MappingData } from 'store/returns/models';
import { fetchAddReturnDataRequest } from 'store/returns/actions';
import { Organisation } from 'store/organisation/models';

export const VatReportPage: React.FC = () => {
  const vatRunData = useSelector(getVatRunData);
  const vatReportData = useSelector(getVatReportData);
  const members = useSelector(getUserMembers);
  const reconciliation = useSelector(getVatReconciliation);
  const activityLogs = useSelector(getVatActivityLogs);
  const prepLoading = useSelector(getVatPrepLoading);
  const loading = useSelector(getVatLoading);
  const success = useSelector(getVatSuccess);
  const currentUser = useSelector(getAuthUser);
  const menuSize = useSelector(getMenuSize);
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const mappings = useSelector(getOrgMappings);
  const excelFileData = useSelector(getExcelFileData);
  const organisations = useSelector(getOrgOrganisations);
  const returnData = useSelector(getReturnData);
  const mappingData = useSelector(getMappingData);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [percent, setPercent] = React.useState<number>(0);
  const [resetValues, setResetValues] = React.useState(false);
  const [pages, setPages] = React.useState<number>(4);
  const [generatedDate, setGeneratedDate] = React.useState<string>(
    moment().format('DD/MM/YYYY HH:mm:ss')
  );
  const [cannotSave, setCannotSave] = React.useState<boolean>(false);
  const [updatingFromPage, setUpdatingFromPage] = React.useState(false);

  const preLoading = useSelector(getReturnPreLoading);
  const [selectedReturnType, setSelectedReturnType] = React.useState<string>();
  const [taxRateOptions, setTaxRateOptions] = React.useState<TaxRateOption[]>();
  const [outOfScopeOptions, setOutOfScopeOptions] =
    React.useState<TaxRateOption[]>();
  const [sections, setSections] = React.useState<Section[]>();
  const [cashAccounting, setCashAccounting] = React.useState<boolean>(false);
  const [outOfScopeCode, setOutOfScopeCode] = React.useState<string>('OOS');
  const [selectedRates, setSelectedRates] = React.useState<SelectedRate[]>([]);
  const resetFinished = () => setResetValues(false);

  const unblockRef = React.useRef();

  const selectedMapping = mappings?.find(
    (m) => m.mappingId === vatRunData?.mappingId
  );
  const selectedOrg = organisations?.find(
    (o) => o.connectionId === vatRunData?.tokenId
  ) as Organisation;
  const retFreq = selectedMapping?.returnFrequency
    ? selectedMapping?.returnFrequency
    : '';
  const goBack = () => {
    history.goBack();
  };

  const reloadReportData = (data: VatRunData, isSaved: boolean) => {
    const fromDateFormat = data.fromDate.includes('-')
      ? 'YYYY-MM-DD'
      : 'DD/MM/YYYY';
    const vatGenerateRequestData: VatGenerateRequestData = {
      connectionId: data.tokenId,
      mappingId: data.mappingId,
      fromDate: moment(data.fromDate, fromDateFormat).format('YYYY-MM-DD'),
      toDate: data.toDate,
      currency: data.currency,
      dataLogId: data.dataLogId,
    };
    //const defaultMap = new Map<string, number>();
    //setSubsectionsMap(defaultMap);
    setResetValues(true);
    setPercent(0);

    const additonalPages = data.dataLogId ? 1 : 0;
    let pages = 4 + additonalPages;

    if (data.taxType === VatTaxReportType.RECAP) pages = 2 + additonalPages;
    setPages(pages);
    if (
      data.dataLogId === undefined ||
      data.dataLogId === null ||
      (isSaved && !!data.dataLogId)
    ) {
      const journalsTotal =
        !vatReportData || !vatReportData.journals
          ? 0
          : vatReportData.journals.length;
      const exceptionsTotal =
        !vatReportData || !vatReportData.exceptions
          ? 0
          : vatReportData.exceptions.length;
      const totals = !isSaved ? 0 : journalsTotal + exceptionsTotal;

      dispatch(generateReportRequest(vatGenerateRequestData, totals));
    } else {
      const vatLoadRequestData: VatLoadRequestData = {
        ...vatGenerateRequestData,
        dataLogId: data.dataLogId,
      };
      dispatch(loadReportRequest(vatLoadRequestData));
      dispatch(fetchActivityLogsRequest(data.dataLogId));
    }
    const connectedProperly = mappedOrganisations?.find(
      (mo) => mo.connectionId === data.tokenId
    )?.connectedProperly;

    data.taxType !== VatTaxReportType.RECAP &&
      connectedProperly &&
      dispatch(loadReconRequest(data.tokenId, data.toDate));
  };

  const saveReturn = (data: VatRunData) => {
    const jsonTotals: JSONTotals[] = [];
    vatReportData?.subsections.forEach((ss) =>
      jsonTotals.push({ boxName: ss.code, boxValue: ss.net.toFixed(2) })
    );

    const saveReturnRequestData: SaveReturnRequestData = {
      connectionId: data.tokenId,
      mappingId: data.mappingId,
      fromDate: data.fromDate,
      toDate: data.toDate,
      generatedDate: generatedDate,
      currency: data.currency,
      reportingTypeId: data.reportingTypeId,
      dataLogId: data.dataLogId,
      jsonTotals,
    };

    setUpdatingFromPage(true);
    dispatch(saveReturnRequest(saveReturnRequestData));
  };
  const cancelNotSaved = (data: VatRunData) => {
    const orgId = mappedOrganisations?.find(
      (mo) => mo.connectionId === data.tokenId
    )?.organisationId;
    orgId &&
      dispatch(push(routes.organisations.organisation.replace(':id', orgId)));
  };

  const revertData = (data: VatRunData) => {
    const viewRunRequestData: ViewRunRequestData = {
      connectionId: data.tokenId,
      mappingId: data.mappingId,
      dataLogId: data.dataLogId,
    };
    dispatch(fetchViewRunDataRequest(viewRunRequestData));
  };

  const cancelReturn = (data: VatRunData) => {
    const connectionId = data.tokenId;
    const mappingId = data.mappingId;
    const dataLogId = data.dataLogId;

    dispatch(cancelReturnRequest(dataLogId, connectionId, mappingId));
  };
  const getReportUrl = (data: VatRunData) =>
    process.env.PUBLIC_URL +
    routes.vat.viewReportLink
      .replace(':connectionId', data.tokenId)
      .replace(':mappingId', data.mappingId)
      .replace(':dataLogId', data.dataLogId);

  const setAsAwaitingApproval = (data: VatRunData) => {
    if (!data.reviewerId) {
      const message: MessageData = {
        title: 'Reviewer not set !',
        description:
          'Setting the status of the report as Awaiting Approval has failed because the reviewer has not been set. Please set the reviewer in order to do this action.',
        type: MessageStates.ERROR,
      };

      dispatch(turnMessageOn(message));
    } else {
      setUpdatingFromPage(true);
      dispatch(
        awaitingApprovalRequest(
          data.dataLogId,
          getReportUrl(data),
          data.mappingId
        )
      );
    }
  };

  const setInternallyApproved = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(
      internallyApproveRequest(
        data.dataLogId,
        getReportUrl(data),
        data.mappingId
      )
    );
  };

  const sendForClientApproval = (values: EmailDataParametersRequest) => {
    if (vatRunData) {
      const emailUrl = getReportUrl(vatRunData);
      const emailData: EmailDataParametersRequest = { ...values, emailUrl };
      setUpdatingFromPage(true);
      dispatch(
        sendClientApprovalRequest(
          vatRunData.dataLogId,
          emailData,
          vatRunData.mappingId
        )
      );
      dispatch(turnModalOff());
    }
  };
  const sendForClientApprovalWindow = (data: VatRunData) => {
    if (!data.clientId) {
      const message: MessageData = {
        title: 'Client is not set !',
        description:
          'Client has not been assigned yet. Please assign a client in order to proceed.',
        type: MessageStates.ERROR,
      };

      dispatch(turnMessageOn(message));
    } else {
      const drawerData: DrawerData = {
        title: t('common.send-to-client'),
        type: DrawerType.NOT_APPROVED_FORM,
        component: (
          <ClientApproveForm sendForClientApproval={sendForClientApproval} />
        ),
      };
      dispatch(turnDrawerOn(drawerData));
    }
  };
  const setClientApproved = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(
      clientApproveRequest(data.dataLogId, getReportUrl(data), data.mappingId)
    );
  };

  const updateActivityRole = (requestData: ActivityRoleUpdateRequest) => {
    setUpdatingFromPage(true);
    dispatch(updateActivityRoleRequest(requestData));
  };

  const setNotApprovedWindow = () => {
    const drawerData: DrawerData = {
      title: t('common.add-note'),
      type: DrawerType.NOT_APPROVED_FORM,
      component: <NotApproveForm setNotApproved={setNotApproved} />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const setNotApproved = (values: EmailDataParametersRequest) => {
    if (vatRunData) {
      const emailUrl = getReportUrl(vatRunData);
      const emailData: EmailDataParametersRequest = { ...values, emailUrl };
      setUpdatingFromPage(true);
      dispatch(
        notApproveRequest(vatRunData.dataLogId, emailData, vatRunData.mappingId)
      );
      dispatch(turnModalOff());
    }
  };

  const markReturnAsSubmitted = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(submitReturnRequest(data.dataLogId, data.mappingId));
  };

  const markReturnAsLocked = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(lockReturnRequest(data.dataLogId, data.mappingId));
  };

  const updateNote = (noteText: string, dataLogId: string) => {
    if (!noteText || noteText === '') {
      const message: MessageData = {
        title: 'Empty note text !',
        description: 'You cannot save an empty field',
        type: MessageStates.WARNING,
      };

      dispatch(turnMessageOn(message));
    } else {
      dispatch(addNoteRequest(noteText, dataLogId));
    }
  };

  const cancelWarningWindow = (data: VatRunData) => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'Cancel report!',
      component: (
        <ConfirmBoxContainer
          callback={cancelReturn}
          param={data}
          description="This will cancel current report. Continue?"
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const loackWarningWindow = (
    vatRunData: VatRunData,
    actionType: DataLogActions
  ) => {
    let callback;
    if (actionType == DataLogActions.LOCK_AS_APPROVED) {
      callback = () => markReturnAsLocked(vatRunData);
    } else if (actionType == DataLogActions.MARK_AS_SUBMITTED) {
      callback = () => markReturnAsSubmitted(vatRunData);
    }

    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'This action will permanently lock the tax submission',
      component: (
        <ConfirmBoxContainer
          callback={callback}
          showCancelBtn={true}
          param2={vatRunData}
          okText="Proceed"
          description="Please confirm you have done all necessary reviews and the data can be locked."
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const goBackWarningWindow = (data: VatRunData, location: any) => {
    const navigate = () => {
      if (unblockRef) {
        unblockRef.current();
      }
      dispatch(turnModalOff());
      history.push(location.pathname);
    };

    if (
      (!data.status ||
        DataLogStatus[data.status] === DataLogStatus.NOT_SAVED) &&
      !cannotSave
    ) {
      const modalData: ModalData = {
        type: WindowType.WARNING,
        title: 'Your report is not saved, do you want to save report?',
        component: (
          <ConfirmBoxContainer
            callback={navigate}
            callback2={saveReturn}
            showCancelBtn={false}
            param2={data}
            okText="Continue without Saving"
            okText2="Save VAT Return"
            description="Are you sure you want to go back? This generated tax report is not saved. Once you click ‘Continue’, you will loose this generated report."
          />
        ),
      };

      dispatch(turnModalOn(modalData));
    } else goBack();
  };

  //useEffect(() => {
  //const warningText = document.getElementById('problem-warning');
  //setCannotSave(warningText ? true : false);
  //}, []);

  const getExcelFileForExport = () => {
    dispatch(fetchExcelFileRequest(vatRunData?.reportingTypeId));
  };

  const onReconnect = () => {
    const drawerData: DrawerData = {
      title: 'Connect entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  useEffect(() => {
    if (vatRunData) {
      if (
        !vatRunData.status ||
        DataLogStatus[vatRunData.status] !== DataLogStatus.NOT_SAVED
      ) {
        if (updatingFromPage) {
          setUpdatingFromPage(false);
        } else {
          reloadReportData(vatRunData, false);
        }
        if (vatRunData.dataLogId) setGeneratedDate(vatRunData.generatedDate);
      } else setGeneratedDate(moment().format('DD/MM/YYYY HH:mm:ss'));

      const noSave =
        (!vatRunData.isPeriodClosed && !vatRunData.dataLogId) ||
        (vatRunData.status !== DataLogStatus.CANCELLED &&
          vatRunData.status !== DataLogStatus.SUBMITTED &&
          vatRunData.isFirstTime === false &&
          vatRunData.validRange !== null &&
          vatRunData.validRange === false); //||
      // (vatRunData.isFirstTime === true &&
      //   vatRunData.validRange !== null &&
      //   vatRunData.validRange === false);

      setCannotSave(noSave);
      selectedOrg.connectedProperly &&
        dispatch(fetchAddReturnDataRequest(vatRunData?.tokenId));
    } else if (!loading) dispatch(push(routes.main));
  }, [vatRunData, members]);

  useEffect(() => {
    if (vatReportData) {
      if (vatReportData.totalPages === 0 || !vatReportData.totalPages) {
        setPercent(100);
      } else {
        const singlePage = Math.round(100 / vatReportData.totalPages);
        const percentage =
          vatReportData.totalPages === vatReportData.page
            ? 100
            : singlePage * vatReportData.page;
        setPercent(percentage);
      }
    } else {
      setPercent(0);
    }
    unblockRef.current = history.block((location: any, _action: any) => {
      if (
        (!vatRunData?.status ||
          DataLogStatus[vatRunData.status] === DataLogStatus.NOT_SAVED) &&
        !cannotSave &&
        vatReportData
      ) {
        vatRunData && goBackWarningWindow(vatRunData, location);
        return false;
      }
      return true;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [vatReportData, vatRunData]);

  const prepareTaxRateOptions = () => {
    const opts: TaxRateOption[] = [];
    returnData?.taxRates?.map((tr) =>
      opts.push({
        value: tr.id,
        label: `${tr.name} [${tr.taxRate.toString()}%]`,
      })
    );
    return opts;
  };

  const sortSelectionsByReturnType = (returnType: string) => {
    setSelectedReturnType(returnType);
    const hasCA = returnData?.returnTypesEdit?.find(
      (rt) => rt.id === returnType
    )?.cashAccountingEnabled;

    hasCA ? setCashAccounting(true) : setCashAccounting(false);
    const sec = returnData?.sections?.filter((s) => {
      return (
        s.returnTypeId === returnType ||
        (hasCA && s.name === s.subsections[0].code)
      );
    });

    sec?.sort((a, b) => a.sortOrder - b.sortOrder);
    sec && setSections(sec);
    const ooc = sec?.find((s) => s.subsections[0].outOfScope)?.subsections[0]
      .code;
    ooc && setOutOfScopeCode(ooc);
  };

  const setMappingData = (data: MappingData) => {
    dispatch(updateMappingData(data));
  };

  const loadExistingMapping = (mapId: string, isTemplate = false) => {
    const savingMapId = isTemplate ? vatRunData?.mappingId : mapId;
    const selMap = mappings?.find((m) => m.mappingId === mapId);

    const savingPeriodFromDate = isTemplate
      ? mappingData?.periodFromDate
      : selMap?.periodFromDate;
    const savingReturnDueDate = isTemplate
      ? mappingData?.returnDueDate
      : selMap?.returnDueDate;
    const savingReturnFrequency = isTemplate
      ? mappingData?.returnFrequency
      : selMap?.returnFrequency;
    let jsonMapping;
    if (selMap?.jsonMapping) {
      try {
        const parsedMapping = JSON.parse(selMap.jsonMapping);

        jsonMapping = parsedMapping?.mapping;
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    if (
      returnData?.taxRates &&
      returnData.taxRates.length < jsonMapping.length
    ) {
      const toDelete = jsonMapping.filter(
        (jm) =>
          returnData.taxRates?.findIndex((tr) => tr.taxType === jm.taxType) ===
          -1
      );
      toDelete.map((td) => {
        jsonMapping.splice(
          jsonMapping.findIndex((jm) => jm.taxType === td.taxType),
          1
        );
      });
    }

    const mapData = {
      mappingId: savingMapId,
      templateMappingId: mapId,
      typeId: returnData?.returnTypesEdit?.find(
        (rt) => rt.name === selMap?.mappingName
      )?.id,
      countryId: returnData?.returnTypesEdit?.find(
        (rt) => rt.name === selMap?.mappingName
      )?.countryId,
      mappedCashAccounting: selMap?.cashAccounting,
      periodFromDate: moment(savingPeriodFromDate).format('YYYY-MM-DD'),
      returnDueDate: moment(savingReturnDueDate).format('YYYY-MM-DD'),
      returnFrequency: savingReturnFrequency,
      jsonMapping,
    };
    setMappingData(mapData);
  };

  useEffect(() => {
    if (!preLoading) {
      const retTypes = returnData?.returnTypesEdit;
      if (retTypes && retTypes.length > 0) {
        loadExistingMapping(vatRunData?.mappingId);
      }
    }
  }, [preLoading, mappings]);

  useEffect(() => {
    if (returnData?.taxRates) {
      const opts: TaxRateOption[] = prepareTaxRateOptions();
      setTaxRateOptions(opts);
      setOutOfScopeOptions(opts);
      mappingData?.typeId && sortSelectionsByReturnType(mappingData?.typeId);
    }
    // } else if (!returnData) {
    //   vatRunData?.tokenId &&
    //     dispatch(fetchAddReturnDataRequest(vatRunData?.tokenId));
    // }
  }, [returnData]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Tax return | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for users" />
      </Helmet>

      <Spin spinning={prepLoading} size="large">
        {vatRunData && currentUser && (
          <VatReport
            loading={loading}
            success={success}
            vatRunData={vatRunData}
            vatReportData={vatReportData}
            //goBack={goBackWarningWindow}
            generatedDate={generatedDate}
            reloadReportData={reloadReportData}
            currentUser={currentUser}
            //subsectionsMap={subsectionsMap}
            //updateSubsectionsMap={updateSubsectionsMap}
            loadingPercent={percent}
            resetValues={resetValues}
            resetFinished={resetFinished}
            reconciliation={reconciliation}
            pages={pages}
            saveReturn={saveReturn}
            revertData={revertData}
            cancelReturn={cancelWarningWindow}
            setAwaitingApproval={setAsAwaitingApproval}
            setInternallyApproved={setInternallyApproved}
            updateActivityRole={updateActivityRole}
            sendForClientApproval={sendForClientApprovalWindow}
            setClientApproved={setClientApproved}
            setNotApproved={setNotApprovedWindow}
            cancelNotSaved={cancelNotSaved}
            activityLogs={activityLogs}
            updateNote={updateNote}
            cannotSave={cannotSave}
            menuSize={menuSize}
            currentOrganisation={mappedOrganisations?.find(
              (mo) => mo.connectionId === vatRunData.tokenId
            )}
            retFreq={retFreq}
            onReconnect={onReconnect}
            getExcelFileForExport={getExcelFileForExport}
            excelFileData={excelFileData}
            returnData={returnData}
            mappingData={mappingData}
            prepareTaxRateOptions={prepareTaxRateOptions}
            sections={sections}
            taxRateOptions={taxRateOptions}
            outOfScopeOptions={outOfScopeOptions}
            loackWarningWindow={loackWarningWindow}
          />
        )}
      </Spin>
    </React.Fragment>
  );
};
