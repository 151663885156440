//@ts-nocheck
import React from 'react';
import { Popconfirm } from 'antd';
import styled from 'styled-components';

interface CustomPopconfirmProps extends TooltipProps {
  className?: string;
  children: any;
  restProps: any;
}

const CustomPopconfirm = ({
  className,
  children,
  ...restProps
}: CustomPopconfirmProps) => (
  <Popconfirm overlayClassName={className} {...restProps}>
    {children}
  </Popconfirm>
);

export const StyledPopconfirm = styled(CustomPopconfirm)`
  .ant-popover-inner {
    border-radius: 20px !important;
    padding: 20px !important;
  }

  .ant-popconfirm-message-text {
    text-align: center !important;
  }

  .ant-popconfirm-buttons {
    text-align: center !important;
    white-space: nowrap;
    padding-top: 15px;
  }

  .ant-popconfirm-buttons > button {
    width: 107px;
    height: 25px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    border-radius: 50px;
  }

  .ant-btn-primary:hover {
    background-color: #ffbc3e !important;
    color: #042a2a !important;
    border-color: #64bbbd !important;
    box-shadow: none;
  }

  .ant-btn-default:hover {
    box-shadow: none;
  }

  .ant-popconfirm-message-icon {
    display: none;
  }

  .ant-popconfirm-title,
  ant-popconfirm-description {
    color: #042a2a !important;
  }
`;
