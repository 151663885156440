import { call, put, select } from 'redux-saga/effects';
import {
  addMemberFailure,
  addMemberSuccess,
  fetchUserRightsRequest,
  fetchUsersRequest,
} from '../actions';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { AddMemberResponse, addMember } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { AddMemberRequestAction } from '../types';
import { User } from 'store/auth/models';
import { AccountType, MessageStatus } from 'store/api/enums';
import { getAuthUser } from 'store/auth/selectors';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* addMemberSaga(action: AddMemberRequestAction) {
  try {
    let err: ApiError | null;
    const { newMember, getRights } = action.payload;
    const user = {
      firstName: newMember.firstName,
      lastName: newMember.lastName,
      email: newMember.email,
    } as User;
    const isClient = newMember.isClient;
    const response: AddMemberResponse = yield call(addMember, user, isClient);

    err = checkResponse(response?.responseMessage);
    if (err && response.responseMessage.status === MessageStatus.UNAUTHORIZED) {
      yield put(fetchUsersRequest());
    }
    if (err) throw err;
    const accountType = isClient ? 'CLIENT' : 'STAFF';
    const accountUuid = response.data.accountUuid;
    const userUuid = response.data.userUuid;
    const currentUser = yield select(getAuthUser);
    const accountCreatorName = `${currentUser.firstName} ${currentUser.lastName}`;
    const canManageOrgs = false;
    const canManageUsers = false;
    const accountActivated = false;
    const newUser = {
      ...user,
      accountType,
      accountUuid,
      userUuid,
      accountCreatorName,
      canManageOrgs,
      canManageUsers,
      accountActivated,
    } as User;

    yield put(addMemberSuccess(newUser));

    if (getRights && accountUuid) {
      yield put(fetchUserRightsRequest(accountUuid, userUuid));
    }

    //yield put(fetchUsersRequest());

    const message: MessageData = {
      title: 'Success',
      description: 'New member successfully added',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(addMemberFailure());
  }
}
